import { getImageURL } from '@/lib/get-image-url';
import * as yup from 'yup';
export const PHONE_REGEX = 
// /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
/^(?:(?:(?:\+[1-9]{1,4}[ -]?)|(?:\([0-9]{2,3}\)[ -]?)|(?:[0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4})?$/;
export const PHONE_REGEX_WITH_CC = /^\+[1-9]{1,2}[0-9]{1,10}$/;
export const PASSWORD_REGEX = /^(?=.{8,50}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/gm;
export const COUNTRY_LIST = [
    {
        label: 'Afghanistan',
        value: 'AF',
    },
    {
        label: 'Albania',
        value: 'AL',
    },
    {
        label: 'Algeria',
        value: 'DZ',
    },
    {
        label: 'American Samoa',
        value: 'AS',
    },
    {
        label: 'Andorra',
        value: 'AD',
    },
    {
        label: 'Angola',
        value: 'AO',
    },
    {
        label: 'Anguilla',
        value: 'AI',
    },
    {
        label: 'Antarctica',
        value: 'AQ',
    },
    {
        label: 'Antigua and Barbuda',
        value: 'AG',
    },
    {
        label: 'Argentina',
        value: 'AR',
    },
    {
        label: 'Armenia',
        value: 'AM',
    },
    {
        label: 'Aruba',
        value: 'AW',
    },
    {
        label: 'Australia',
        value: 'AU',
    },
    {
        label: 'Austria',
        value: 'AT',
    },
    {
        label: 'Azerbaijan',
        value: 'AZ',
    },
    {
        label: 'Bahamas (the)',
        value: 'BS',
    },
    {
        label: 'Bahrain',
        value: 'BH',
    },
    {
        label: 'Bangladesh',
        value: 'BD',
    },
    {
        label: 'Barbados',
        value: 'BB',
    },
    {
        label: 'Belarus',
        value: 'BY',
    },
    {
        label: 'Belgium',
        value: 'BE',
    },
    {
        label: 'Belize',
        value: 'BZ',
    },
    {
        label: 'Benin',
        value: 'BJ',
    },
    {
        label: 'Bermuda',
        value: 'BM',
    },
    {
        label: 'Bhutan',
        value: 'BT',
    },
    {
        label: 'Bolivia (Plurinational State of)',
        value: 'BO',
    },
    {
        label: 'Bonaire, Sint Eustatius and Saba',
        value: 'BQ',
    },
    {
        label: 'Bosnia and Herzegovina',
        value: 'BA',
    },
    {
        label: 'Botswana',
        value: 'BW',
    },
    {
        label: 'Bouvet Island',
        value: 'BV',
    },
    {
        label: 'Brazil',
        value: 'BR',
    },
    {
        label: 'British Indian Ocean Territory (the)',
        value: 'IO',
    },
    {
        label: 'Brunei Darussalam',
        value: 'BN',
    },
    {
        label: 'Bulgaria',
        value: 'BG',
    },
    {
        label: 'Burkina Faso',
        value: 'BF',
    },
    {
        label: 'Burundi',
        value: 'BI',
    },
    {
        label: 'Cabo Verde',
        value: 'CV',
    },
    {
        label: 'Cambodia',
        value: 'KH',
    },
    {
        label: 'Cameroon',
        value: 'CM',
    },
    {
        label: 'Canada',
        value: 'CA',
    },
    {
        label: 'Cayman Islands (the)',
        value: 'KY',
    },
    {
        label: 'Central African Republic (the)',
        value: 'CF',
    },
    {
        label: 'Chad',
        value: 'TD',
    },
    {
        label: 'Chile',
        value: 'CL',
    },
    {
        label: 'China',
        value: 'CN',
    },
    {
        label: 'Christmas Island',
        value: 'CX',
    },
    {
        label: 'Cocos (Keeling) Islands (the)',
        value: 'CC',
    },
    {
        label: 'Colombia',
        value: 'CO',
    },
    {
        label: 'Comoros (the)',
        value: 'KM',
    },
    {
        label: 'Congo (the Democratic Republic of the)',
        value: 'CD',
    },
    {
        label: 'Congo (the)',
        value: 'CG',
    },
    {
        label: 'Cook Islands (the)',
        value: 'CK',
    },
    {
        label: 'Costa Rica',
        value: 'CR',
    },
    {
        label: 'Croatia',
        value: 'HR',
    },
    {
        label: 'Cuba',
        value: 'CU',
    },
    {
        label: 'Curaçao',
        value: 'CW',
    },
    {
        label: 'Cyprus',
        value: 'CY',
    },
    {
        label: 'Czechia',
        value: 'CZ',
    },
    {
        label: "Côte d'Ivoire",
        value: 'CI',
    },
    {
        label: 'Denmark',
        value: 'DK',
    },
    {
        label: 'Djibouti',
        value: 'DJ',
    },
    {
        label: 'Dominica',
        value: 'DM',
    },
    {
        label: 'Dominican Republic (the)',
        value: 'DO',
    },
    {
        label: 'Ecuador',
        value: 'EC',
    },
    {
        label: 'Egypt',
        value: 'EG',
    },
    {
        label: 'El Salvador',
        value: 'SV',
    },
    {
        label: 'Equatorial Guinea',
        value: 'GQ',
    },
    {
        label: 'Eritrea',
        value: 'ER',
    },
    {
        label: 'Estonia',
        value: 'EE',
    },
    {
        label: 'Eswatini',
        value: 'SZ',
    },
    {
        label: 'Ethiopia',
        value: 'ET',
    },
    {
        label: 'Falkland Islands (the) [Malvinas]',
        value: 'FK',
    },
    {
        label: 'Faroe Islands (the)',
        value: 'FO',
    },
    {
        label: 'Fiji',
        value: 'FJ',
    },
    {
        label: 'Finland',
        value: 'FI',
    },
    {
        label: 'France',
        value: 'FR',
    },
    {
        label: 'French Guiana',
        value: 'GF',
    },
    {
        label: 'French Polynesia',
        value: 'PF',
    },
    {
        label: 'French Southern Territories (the)',
        value: 'TF',
    },
    {
        label: 'Gabon',
        value: 'GA',
    },
    {
        label: 'Gambia (the)',
        value: 'GM',
    },
    {
        label: 'Georgia',
        value: 'GE',
    },
    {
        label: 'Germany',
        value: 'DE',
    },
    {
        label: 'Ghana',
        value: 'GH',
    },
    {
        label: 'Gibraltar',
        value: 'GI',
    },
    {
        label: 'Greece',
        value: 'GR',
    },
    {
        label: 'Greenland',
        value: 'GL',
    },
    {
        label: 'Grenada',
        value: 'GD',
    },
    {
        label: 'Guadeloupe',
        value: 'GP',
    },
    {
        label: 'Guam',
        value: 'GU',
    },
    {
        label: 'Guatemala',
        value: 'GT',
    },
    {
        label: 'Guernsey',
        value: 'GG',
    },
    {
        label: 'Guinea',
        value: 'GN',
    },
    {
        label: 'Guinea-Bissau',
        value: 'GW',
    },
    {
        label: 'Guyana',
        value: 'GY',
    },
    {
        label: 'Haiti',
        value: 'HT',
    },
    {
        label: 'Heard Island and McDonald Islands',
        value: 'HM',
    },
    {
        label: 'Holy See (the)',
        value: 'VA',
    },
    {
        label: 'Honduras',
        value: 'HN',
    },
    {
        label: 'Hong Kong',
        value: 'HK',
    },
    {
        label: 'Hungary',
        value: 'HU',
    },
    {
        label: 'Iceland',
        value: 'IS',
    },
    {
        label: 'India',
        value: 'IN',
    },
    {
        label: 'Indonesia',
        value: 'ID',
    },
    {
        label: 'Iran (Islamic Republic of)',
        value: 'IR',
    },
    {
        label: 'Iraq',
        value: 'IQ',
    },
    {
        label: 'Ireland',
        value: 'IE',
    },
    {
        label: 'Isle of Man',
        value: 'IM',
    },
    {
        label: 'Israel',
        value: 'IL',
    },
    {
        label: 'Italy',
        value: 'IT',
    },
    {
        label: 'Jamaica',
        value: 'JM',
    },
    {
        label: 'Japan',
        value: 'JP',
    },
    {
        label: 'Jersey',
        value: 'JE',
    },
    {
        label: 'Jordan',
        value: 'JO',
    },
    {
        label: 'Kazakhstan',
        value: 'KZ',
    },
    {
        label: 'Kenya',
        value: 'KE',
    },
    {
        label: 'Kiribati',
        value: 'KI',
    },
    {
        label: "Korea (the Democratic People's Republic of)",
        value: 'KP',
    },
    {
        label: 'Korea (the Republic of)',
        value: 'KR',
    },
    {
        label: 'Kuwait',
        value: 'KW',
    },
    {
        label: 'Kyrgyzstan',
        value: 'KG',
    },
    {
        label: "Lao People's Democratic Republic (the)",
        value: 'LA',
    },
    {
        label: 'Latvia',
        value: 'LV',
    },
    {
        label: 'Lebanon',
        value: 'LB',
    },
    {
        label: 'Lesotho',
        value: 'LS',
    },
    {
        label: 'Liberia',
        value: 'LR',
    },
    {
        label: 'Libya',
        value: 'LY',
    },
    {
        label: 'Liechtenstein',
        value: 'LI',
    },
    {
        label: 'Lithuania',
        value: 'LT',
    },
    {
        label: 'Luxembourg',
        value: 'LU',
    },
    {
        label: 'Macao',
        value: 'MO',
    },
    {
        label: 'Madagascar',
        value: 'MG',
    },
    {
        label: 'Malawi',
        value: 'MW',
    },
    {
        label: 'Malaysia',
        value: 'MY',
    },
    {
        label: 'Maldives',
        value: 'MV',
    },
    {
        label: 'Mali',
        value: 'ML',
    },
    {
        label: 'Malta',
        value: 'MT',
    },
    {
        label: 'Marshall Islands (the)',
        value: 'MH',
    },
    {
        label: 'Martinique',
        value: 'MQ',
    },
    {
        label: 'Mauritania',
        value: 'MR',
    },
    {
        label: 'Mauritius',
        value: 'MU',
    },
    {
        label: 'Mayotte',
        value: 'YT',
    },
    {
        label: 'Mexico',
        value: 'MX',
    },
    {
        label: 'Micronesia (Federated States of)',
        value: 'FM',
    },
    {
        label: 'Moldova (the Republic of)',
        value: 'MD',
    },
    {
        label: 'Monaco',
        value: 'MC',
    },
    {
        label: 'Mongolia',
        value: 'MN',
    },
    {
        label: 'Montenegro',
        value: 'ME',
    },
    {
        label: 'Montserrat',
        value: 'MS',
    },
    {
        label: 'Morocco',
        value: 'MA',
    },
    {
        label: 'Mozambique',
        value: 'MZ',
    },
    {
        label: 'Myanmar',
        value: 'MM',
    },
    {
        label: 'Namibia',
        value: 'NA',
    },
    {
        label: 'Nauru',
        value: 'NR',
    },
    {
        label: 'Nepal',
        value: 'NP',
    },
    {
        label: 'Netherlands (the)',
        value: 'NL',
    },
    {
        label: 'New Caledonia',
        value: 'NC',
    },
    {
        label: 'New Zealand',
        value: 'NZ',
    },
    {
        label: 'Nicaragua',
        value: 'NI',
    },
    {
        label: 'Niger (the)',
        value: 'NE',
    },
    {
        label: 'Nigeria',
        value: 'NG',
    },
    {
        label: 'Niue',
        value: 'NU',
    },
    {
        label: 'Norfolk Island',
        value: 'NF',
    },
    {
        label: 'Northern Mariana Islands (the)',
        value: 'MP',
    },
    {
        label: 'Norway',
        value: 'NO',
    },
    {
        label: 'Oman',
        value: 'OM',
    },
    {
        label: 'Pakistan',
        value: 'PK',
    },
    {
        label: 'Palau',
        value: 'PW',
    },
    {
        label: 'Palestine, State of',
        value: 'PS',
    },
    {
        label: 'Panama',
        value: 'PA',
    },
    {
        label: 'Papua New Guinea',
        value: 'PG',
    },
    {
        label: 'Paraguay',
        value: 'PY',
    },
    {
        label: 'Peru',
        value: 'PE',
    },
    {
        label: 'Philippines (the)',
        value: 'PH',
    },
    {
        label: 'Pitcairn',
        value: 'PN',
    },
    {
        label: 'Poland',
        value: 'PL',
    },
    {
        label: 'Portugal',
        value: 'PT',
    },
    {
        label: 'Puerto Rico',
        value: 'PR',
    },
    {
        label: 'Qatar',
        value: 'QA',
    },
    {
        label: 'Republic of North Macedonia',
        value: 'MK',
    },
    {
        label: 'Romania',
        value: 'RO',
    },
    {
        label: 'Russian Federation (the)',
        value: 'RU',
    },
    {
        label: 'Rwanda',
        value: 'RW',
    },
    {
        label: 'Réunion',
        value: 'RE',
    },
    {
        label: 'Saint Barthélemy',
        value: 'BL',
    },
    {
        label: 'Saint Helena, Ascension and Tristan da Cunha',
        value: 'SH',
    },
    {
        label: 'Saint Kitts and Nevis',
        value: 'KN',
    },
    {
        label: 'Saint Lucia',
        value: 'LC',
    },
    {
        label: 'Saint Martin (French part)',
        value: 'MF',
    },
    {
        label: 'Saint Pierre and Miquelon',
        value: 'PM',
    },
    {
        label: 'Saint Vincent and the Grenadines',
        value: 'VC',
    },
    {
        label: 'Samoa',
        value: 'WS',
    },
    {
        label: 'San Marino',
        value: 'SM',
    },
    {
        label: 'Sao Tome and Principe',
        value: 'ST',
    },
    {
        label: 'Saudi Arabia',
        value: 'SA',
    },
    {
        label: 'Senegal',
        value: 'SN',
    },
    {
        label: 'Serbia',
        value: 'RS',
    },
    {
        label: 'Seychelles',
        value: 'SC',
    },
    {
        label: 'Sierra Leone',
        value: 'SL',
    },
    {
        label: 'Singapore',
        value: 'SG',
    },
    {
        label: 'Sint Maarten (Dutch part)',
        value: 'SX',
    },
    {
        label: 'Slovakia',
        value: 'SK',
    },
    {
        label: 'Slovenia',
        value: 'SI',
    },
    {
        label: 'Solomon Islands',
        value: 'SB',
    },
    {
        label: 'Somalia',
        value: 'SO',
    },
    {
        label: 'South Africa',
        value: 'ZA',
    },
    {
        label: 'South Georgia and the South Sandwich Islands',
        value: 'GS',
    },
    {
        label: 'South Sudan',
        value: 'SS',
    },
    {
        label: 'Spain',
        value: 'ES',
    },
    {
        label: 'Sri Lanka',
        value: 'LK',
    },
    {
        label: 'Sudan (the)',
        value: 'SD',
    },
    {
        label: 'Suriname',
        value: 'SR',
    },
    {
        label: 'Svalbard and Jan Mayen',
        value: 'SJ',
    },
    {
        label: 'Sweden',
        value: 'SE',
    },
    {
        label: 'Switzerland',
        value: 'CH',
    },
    {
        label: 'Syrian Arab Republic',
        value: 'SY',
    },
    {
        label: 'Taiwan',
        value: 'TW',
    },
    {
        label: 'Tajikistan',
        value: 'TJ',
    },
    {
        label: 'Tanzania, United Republic of',
        value: 'TZ',
    },
    {
        label: 'Thailand',
        value: 'TH',
    },
    {
        label: 'Timor-Leste',
        value: 'TL',
    },
    {
        label: 'Togo',
        value: 'TG',
    },
    {
        label: 'Tokelau',
        value: 'TK',
    },
    {
        label: 'Tonga',
        value: 'TO',
    },
    {
        label: 'Trinidad and Tobago',
        value: 'TT',
    },
    {
        label: 'Tunisia',
        value: 'TN',
    },
    {
        label: 'Turkey',
        value: 'TR',
    },
    {
        label: 'Turkmenistan',
        value: 'TM',
    },
    {
        label: 'Turks and Caicos Islands (the)',
        value: 'TC',
    },
    {
        label: 'Tuvalu',
        value: 'TV',
    },
    {
        label: 'Uganda',
        value: 'UG',
    },
    {
        label: 'Ukraine',
        value: 'UA',
    },
    {
        label: 'United Arab Emirates (the)',
        value: 'AE',
    },
    // {
    //   label: 'United Kingdom of Great Britain and Northern Ireland (the)',
    //   value: 'GB',
    // },
    {
        label: 'United States Minor Outlying Islands (the)',
        value: 'UM',
    },
    {
        label: 'United States of America (the)',
        value: 'US',
    },
    {
        label: 'Uruguay',
        value: 'UY',
    },
    {
        label: 'Uzbekistan',
        value: 'UZ',
    },
    {
        label: 'Vanuatu',
        value: 'VU',
    },
    {
        label: 'Venezuela (Bolivarian Republic of)',
        value: 'VE',
    },
    {
        label: 'Viet Nam',
        value: 'VN',
    },
    {
        label: 'Virgin Islands (British)',
        value: 'VG',
    },
    {
        label: 'Virgin Islands (U.S.)',
        value: 'VI',
    },
    {
        label: 'Wallis and Futuna',
        value: 'WF',
    },
    {
        label: 'Western Sahara',
        value: 'EH',
    },
    {
        label: 'Yemen',
        value: 'YE',
    },
    {
        label: 'Zambia',
        value: 'ZM',
    },
    {
        label: 'Zimbabwe',
        value: 'ZW',
    },
    {
        label: 'Åland Islands',
        value: 'AX',
    },
];
export const REGISTER_COUNTRY_LIST = [
    { label: 'Albania', value: 'AL' },
    { label: 'Armenia', value: 'AM' },
    { label: 'Austria', value: 'AT' },
    { label: 'Azerbaijan', value: 'AZ' },
    { label: 'Belarus', value: 'BY' },
    { label: 'Belgium', value: 'BE' },
    { label: 'Bosnia and Herzegovina', value: 'BA' },
    { label: 'Bulgaria', value: 'BG' },
    { label: 'Croatia', value: 'HR' },
    { label: 'Cyprus', value: 'CY' },
    { label: 'Czech Republic', value: 'CZ' },
    { label: 'Denmark', value: 'DK' },
    { label: 'Estonia', value: 'EE' },
    { label: 'Finland', value: 'FI' },
    { label: 'France', value: 'FR' },
    { label: 'Georgia', value: 'GE' },
    { label: 'Germany', value: 'DE' },
    { label: 'Greece', value: 'GR' },
    { label: 'Hungary', value: 'HU' },
    { label: 'Iceland', value: 'IS' },
    { label: 'Ireland', value: 'IE' },
    { label: 'Italy', value: 'IT' },
    { label: 'Kazakhstan', value: 'KZ' },
    { label: 'Kyrgyzstan', value: 'KG' },
    { label: 'Latvia', value: 'LV' },
    { label: 'Lithuania', value: 'LT' },
    { label: 'Luxembourg', value: 'LU' },
    { label: 'Macedonia', value: 'MK' },
    { label: 'Malta', value: 'MT' },
    { label: 'Moldova', value: 'MD' },
    { label: 'Monaco', value: 'MC' },
    { label: 'Montenegro', value: 'ME' },
    { label: 'Netherlands', value: 'NL' },
    { label: 'Norway', value: 'NO' },
    { label: 'Poland', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Republic of Kosovo', value: 'XK' },
    { label: 'Romania', value: 'RO' },
    { label: 'Serbia', value: 'RS' },
    { label: 'Slovakia', value: 'SK' },
    { label: 'Slovenia', value: 'SI' },
    { label: 'Spain', value: 'ES' },
    { label: 'Sweden', value: 'SE' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Tajikistan', value: 'TJ' },
    { label: 'Turkmenistan', value: 'TM' },
    { label: 'Ukraine', value: 'UA' },
    { label: 'Uzbekistan', value: 'UZ' },
];
export const REGISTER_COUNTRY_LIST2 = [
    {
        "label": "Albania",
        "value": "Albania"
    },
    {
        "label": "Armenia",
        "value": "Armenia"
    },
    {
        "label": "Austria",
        "value": "Austria"
    },
    {
        "label": "Azerbaijan",
        "value": "Azerbaijan"
    },
    {
        "label": "Belarus",
        "value": "Belarus"
    },
    {
        "label": "Belgium",
        "value": "Belgium"
    },
    {
        "label": "Bosnia and Herzegovina",
        "value": "Bosnia and Herzegovina"
    },
    {
        "label": "Bulgaria",
        "value": "Bulgaria"
    },
    {
        "label": "Croatia",
        "value": "Croatia"
    },
    {
        "label": "Cyprus",
        "value": "Cyprus"
    },
    {
        "label": "Czech Republic",
        "value": "Czech Republic"
    },
    {
        "label": "Denmark",
        "value": "Denmark"
    },
    {
        "label": "Estonia",
        "value": "Estonia"
    },
    {
        "label": "Finland",
        "value": "Finland"
    },
    {
        "label": "France",
        "value": "France"
    },
    {
        "label": "Georgia",
        "value": "Georgia"
    },
    {
        "label": "Germany",
        "value": "Germany"
    },
    {
        "label": "Greece",
        "value": "Greece"
    },
    {
        "label": "Hungary",
        "value": "Hungary"
    },
    {
        "label": "Iceland",
        "value": "Iceland"
    },
    {
        "label": "Ireland",
        "value": "Ireland"
    },
    {
        "label": "Italy",
        "value": "Italy"
    },
    {
        "label": "Kazakhstan",
        "value": "Kazakhstan"
    },
    {
        "label": "Kyrgyzstan",
        "value": "Kyrgyzstan"
    },
    {
        "label": "Latvia",
        "value": "Latvia"
    },
    {
        "label": "Lithuania",
        "value": "Lithuania"
    },
    {
        "label": "Luxembourg",
        "value": "Luxembourg"
    },
    {
        "label": "Macedonia",
        "value": "Macedonia"
    },
    {
        "label": "Malta",
        "value": "Malta"
    },
    {
        "label": "Moldova",
        "value": "Moldova"
    },
    {
        "label": "Monaco",
        "value": "Monaco"
    },
    {
        "label": "Montenegro",
        "value": "Montenegro"
    },
    {
        "label": "Netherlands",
        "value": "Netherlands"
    },
    {
        "label": "Norway",
        "value": "Norway"
    },
    {
        "label": "Poland",
        "value": "Poland"
    },
    {
        "label": "Portugal",
        "value": "Portugal"
    },
    {
        "label": "Republic of Kosovo",
        "value": "Republic of Kosovo"
    },
    {
        "label": "Romania",
        "value": "Romania"
    },
    {
        "label": "Serbia",
        "value": "Serbia"
    },
    {
        "label": "Slovakia",
        "value": "Slovakia"
    },
    {
        "label": "Slovenia",
        "value": "Slovenia"
    },
    {
        "label": "Spain",
        "value": "Spain"
    },
    {
        "label": "Sweden",
        "value": "Sweden"
    },
    {
        "label": "Switzerland",
        "value": "Switzerland"
    },
    {
        "label": "Tajikistan",
        "value": "Tajikistan"
    },
    {
        "label": "Turkmenistan",
        "value": "Turkmenistan"
    },
    {
        "label": "Ukraine",
        "value": "Ukraine"
    },
    {
        "label": "Uzbekistan",
        "value": "Uzbekistan"
    }
];
export const PRODUCT_CATEGORIES = [
    {
        heading: 'Curlers and Straighteners',
        list: [
            { id: 'cs1', name: 'Curlers', selected: false },
            { id: 'cs2', name: 'Straighteners', selected: false },
        ],
    },
    {
        heading: 'Hair Care',
        list: [
            { id: 'hc1', name: 'S1 Keratin', selected: false },
            { id: 'hc2', name: 'S1 Hair Care', selected: false },
            { id: 'hc3', name: 'Peptame Hair Care', selected: false },
            { id: 'hc4', name: 'Peptame Professional Treatments', selected: false },
        ],
    },
    {
        heading: 'Services',
        list: [{ id: 'sr1', name: 'Online education', selected: false }],
    },
    {
        heading: 'Hair Extensions',
        list: [
            { id: 'he1', name: 'Clip in Extensions', selected: false },
            { id: 'he2', name: 'Tape Hair Extensions', selected: false },
            { id: 'he3', name: 'Pre Bonded Hair Extensions', selected: false },
            { id: 'he4', name: 'Weft Hair Extensions', selected: false },
        ],
    },
    {
        heading: 'Accessories',
        list: [
            { id: 'acc1', name: 'Tools', selected: false },
            { id: 'acc2', name: 'Tapes', selected: false },
            { id: 'acc3', name: 'Marketing', selected: false },
            { id: 'acc4', name: 'Brushes & Combs', selected: false },
        ],
    },
];
export const PRODUCT_BRANDS = {
    heading: 'Brands',
    list: [
        {
            name: 'Peptame',
            selected: false,
        },
        {
            name: 'Seamless1',
            selected: false,
        },
    ],
};
export const COURSES_LIST = [
    {
        id: 1,
        images: [],
        image: getImageURL('seamless1-online-education-intro-001-cdq2-marwuthqkywfyc3qrra1ch.webp'),
        title: 'Intro',
        time: '03:08 minutes',
        text: 'Welcome to the world of hair extensions! In this series of videos, we will share our expertise to guide you into becoming a master at applying hair extensions.',
        showNavs: false,
        loading: false,
        uuid: '00267a45-a37a-49fb-a7a2-e24d6a127a4b',
    },
    {
        id: 2,
        images: [],
        image: getImageURL('seamless1-online-education-tape-extensions-002-cdq2-jdphj1zx1epnfjjuu5fjd7.webp'),
        title: 'How to apply tape extensions',
        time: '59:30 minutes',
        text: 'This is a must-watch video. Step-by-step we guide you through the tape extension application process. We have poured our years of experience into making this video to help you succeed. Tape extensions are ideal for adding length, highlights and more. Mastering this crucial skill will aid you in growing your business.',
        showNavs: false,
        loading: false,
        uuid: '63e50262-26bc-462b-8e45-3e8cea44f65c',
    },
    {
        id: 3,
        images: [],
        image: getImageURL('seamless1-online-education-tape-removal-003-cdq2-2xn8d3vbj6vrkhfvpztfxy.webp'),
        title: 'Tape Removal',
        time: '49:52 minutes',
        text: 'Watch this video to learn the skill of tape removal. Learn how to loosen the hair extension tape with adhesive remover. Performing this skill well will help you stand out as an expert in the hair and beauty business. You will love becoming a skilled practitioner.',
        showNavs: false,
        loading: false,
        uuid: 'b4476627-cd9d-461f-9090-d08068bd3129',
    },
    {
        id: 4,
        images: [
            getImageURL('seamless1-online-education-hair-extension-sectioning-004-1-cdq2-7s5b2rnd4xcksglceqvnud.webp'),
            getImageURL('seamless1-online-education-hair-extension-sectioning-004-2-cdq2-7zheumpwnkzscbczg7dnjg.webp'),
            getImageURL('seamless1-online-education-hair-extension-sectioning-004-3-cdq2-mpcqyuoj4rdexnfhhyzlze.webp'),
            getImageURL('seamless1-online-education-hair-extension-sectioning-004-4-cdq2-e5kqvy6dwo9t1fkqmj9ut5.webp'),
        ],
        image: '',
        title: 'Hair Extension Sectioning',
        time: '04:26 minutes',
        text: 'This video will guide you through sectioning. Skillful sectioning will ensure a successful result for your client.',
        showNavs: false,
        loading: false,
        uuid: '7fda06d6-a0c0-465f-b3e5-3e9c52519fc2',
    },
    {
        id: 5,
        images: [],
        image: getImageURL('seamless1-online-education-after-care-005-cdq2-tn9dnwurmtjnhvqtnnmx5m.webp'),
        title: 'After Care',
        time: '06:09 minutes',
        text: 'This bonus video gives tips on how to care for your hair extensions. You will gain ideas regarding hair extension care and how to prolong the life of your extensions. The video will explain how to wash, condition, and brush your extensions. Learn how to care for your extensions every day.',
        showNavs: false,
        loading: false,
        uuid: '54be54d1-6067-4f98-9103-c0eb1c716e79',
    },
    {
        id: 6,
        images: [
            getImageURL('seamless1-online-education-itip-micro-ring-006-1-cdq2-7dthhvfnd2t3cc4qkay4hl.webp'),
            getImageURL('seamless1-online-education-itip-micro-ring-006-2-cdq2-csnmkvnwadmbuxnukt84wp.webp'),
            getImageURL('seamless1-online-education-itip-micro-ring-006-3-cdq2-85pmzrwymrzvrfpxjpbdnx.webp'),
            getImageURL('seamless1-online-education-itip-micro-ring-006-4-cdq2-64bdeuwezwzfr7pprbeqyu.webp'),
            getImageURL('seamless1-online-education-itip-micro-ring-006-5-cdq2-5wup6qfd4beekztzrqompx.webp'),
        ],
        image: '',
        title: 'I tip- Micro Ring',
        time: '31:02 minutes',
        text: 'Watch this video to learn the technique in easy-to-follow steps. You will learn everything you need to know in detail. All tools are described well so you can understand the process easily. An I tip-micro ring is a way of attaching hair extensions with a fixture instead of heat or glue.',
        showNavs: false,
        loading: false,
        uuid: '40987e3b-28e8-4dcb-8718-63c597aa16a6',
    },
    {
        id: 7,
        images: [
            getImageURL('seamless1-online-education-nano-bead-007-1-cdq2-wcmbkgj9cpfohpb5x8hycf.webp'),
            getImageURL('seamless1-online-education-nano-bead-007-2-cdq2-9zpn5kgkfgoucu23eri1fg.webp'),
            getImageURL('seamless1-online-education-nano-bead-007-3-cdq2-ikokyi5m6fuf6bccvi5ryn.webp'),
            getImageURL('seamless1-online-education-nano-bead-007-4-cdq2-pbuckuxh6ujqofw6xgp9lk.webp'),
            getImageURL('seamless1-online-education-nano-bead-007-5-cdq2-ps8fquzdwtk5jolw46dbhm.webp'),
            getImageURL('seamless1-online-education-nano-bead-007-6-cdq2-1uu7c2bruibxdrcgjvbcfn.webp'),
            getImageURL('seamless1-online-education-nano-bead-007-7-cdq2-qb4xsclggzyr4s1lprunps.webp'),
            getImageURL('seamless1-online-education-nano-bead-007-8-cdq2-fpe5qiefvirunbzaeawzfv.webp'),
            getImageURL('seamless1-online-education-nano-bead-007-9-cdq2-k8w4y6gsttyzlejwkq28zn.webp'),
        ],
        image: '',
        title: 'Nano Bead',
        time: '08:13 minutes',
        text: 'This video clearly explains the technique. These silicon-lined nano beads protect your client’s hair and nano bead extensions are perfect for long-term use.',
        showNavs: false,
        loading: false,
        uuid: '225d5685-eb18-4306-b04f-dd006e632a14',
    },
    {
        id: 8,
        images: [
            getImageURL('seamless1-online-education-nail-tip-008-1-cdq2-1snprsalcuu5rqewdzkpkz.webp'),
            getImageURL('seamless1-online-education-nail-tip-008-2-cdq2-mcnssvv99rmhvclkperbup.webp'),
            getImageURL('seamless1-online-education-nail-tip-008-3-cdq2-n1qfjbxrckycttyzmuqskb.webp'),
            getImageURL('seamless1-online-education-nail-tip-008-4-cdq2-nqso1f91lsmvldbpwhtard.webp'),
            getImageURL('seamless1-online-education-nail-tip-008-5-cdq2-ggkdmrbcxhak8faq6mkmxh.webp'),
            getImageURL('seamless1-online-education-nail-tip-008-6-cdq2-4gfw39okmypuc3r3u9rvtp.webp'),
        ],
        image: '',
        title: 'Nail tip using hot tip applicator',
        time: '41:40 minutes',
        text: 'Watch this video to learn how to apply bonded hair and nail tips. This technique is traditional with a single strand pre-bonded extension. Just follow the step-by-step guide.',
        showNavs: false,
        loading: false,
        uuid: '2e86ee22-1acb-4f08-9bb2-37a3dd4a5b5e',
    },
    {
        id: 9,
        images: [
            getImageURL('seamless1-online-education-ultrasonic-machine-009-1-cdq2-uzvzc7frkstchsqgxvaaok.webp'),
            getImageURL('seamless1-online-education-ultrasonic-machine-009-2-cdq2-1y6rnrgspxehek7punxhsb.webp'),
            getImageURL('seamless1-online-education-ultrasonic-machine-009-3-cdq2-2qqzau681k8qyhdxstolq2.webp'),
            getImageURL('seamless1-online-education-ultrasonic-machine-009-4-cdq2-5j4hafmdnuie4jjmdk9uge.webp'),
            getImageURL('seamless1-online-education-ultrasonic-machine-009-5-cdq2-q7ehu7turndxgevkbwsfes.webp'),
            getImageURL('seamless1-online-education-ultrasonic-machine-009-6-cdq2-jj17ncvwrznr5b3axzxymk.webp'),
            getImageURL('seamless1-online-education-ultrasonic-machine-009-7-cdq2-3falnqcwxfqehgwymlr1ew.webp'),
        ],
        image: '',
        title: 'Ultrasonic Machine',
        time: '26:16 minutes',
        text: 'This video explains an amazing technique. Using cold-fusion technology, the ultrasonic machine softens the keratin bond with ultrasound waves. It is completely safe. You can use our keratin rolls again, so the skill so useful to know. Highly recommended!',
        showNavs: false,
        loading: false,
        uuid: 'fcbea1c8-0477-420b-936f-f615b4fb4f59',
    },
    {
        id: 10,
        images: [
            getImageURL('seamless1-online-education-beaded-weft-010-1-cdq2-x4pfgehhibkqhw1pwseshu.webp'),
            getImageURL('seamless1-online-education-beaded-weft-010-2-cdq2-1z197sfpm3gdrdmcrrzkw2.webp'),
            getImageURL('seamless1-online-education-beaded-weft-010-3-cdq2-p7swgfe1ct2zxc9keu1jms.webp'),
            getImageURL('seamless1-online-education-beaded-weft-010-4-cdq2-sxgfpmuo185n1t9dccm8b1.webp'),
            getImageURL('seamless1-online-education-beaded-weft-010-5-cdq2-b7kicnbf8rumazydds2tfd.webp'),
            getImageURL('seamless1-online-education-beaded-weft-010-6-cdq2-jipcxzsnfjcatutgwu2z5j.webp'),
        ],
        image: '',
        title: 'Beaded Weft',
        time: '12:44 minutes',
        text: 'Watch this video to learn the beaded weft technique in easy-to-follow steps. Learn how to attach the hair extension with these silicone-lined beads. Beaded wefts are light, discreet, and long lasting. This skill will help you to become an expert in your field.',
        showNavs: false,
        loading: false,
        uuid: 'b1fbd12f-e7f4-4896-abc8-a3ad7d23add9',
    },
    {
        id: 11,
        images: [
            getImageURL('seamless1-online-education-s1-cape-011-1-cdq2-mdccoovuqfe6x21n28vf96.webp'),
            getImageURL('seamless1-online-education-s1-cape-011-2-cdq2-nhnjy6d3onvevurgwqulxw.webp'),
            getImageURL('seamless1-online-education-s1-cape-011-3-cdq2-apkbbyqamwd8hnqerb9myg.webp'),
            getImageURL('seamless1-online-education-s1-cape-011-4-cdq2-ixtebbegc2payzj29ppsj9.webp'),
        ],
        image: '',
        title: 'Seamless1 Cape',
        time: '02:02 minutes',
        text: 'This is a brilliant video explaining the Seamless1 cape. Watch for an easy-to understand guide on putting a cape on your client.',
        showNavs: false,
        loading: false,
        uuid: '27a57041-2a1a-4441-b8ed-6f24fb5be1e1',
    },
];
export const COUNTRY_CALLING_CODES = [
    {
        name: 'Albania',
        dial_code: '+355',
        code: 'AL',
        example: 'XXX XX XXX XX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg',
    },
    {
        name: 'Armenia',
        dial_code: '+374',
        code: 'AM',
        example: 'XXX XX XX XX',
        max_length: 9,
        min_length: 8,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg',
    },
    {
        name: 'Austria',
        dial_code: '+43',
        code: 'AT',
        example: 'XXXX XXXXXX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg',
    },
    {
        name: 'Azerbaijan',
        dial_code: '+994',
        code: 'AZ',
        example: 'XXX XXX XX XX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg',
    },
    {
        name: 'Belarus',
        dial_code: '+375',
        code: 'BY',
        example: 'X XXX XXX XX XX',
        max_length: 12,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg',
    },
    {
        name: 'Belgium',
        dial_code: '+32',
        code: 'BE',
        example: 'XXXX XX XX XX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg',
    },
    {
        name: 'Bosnia and Herzegovina',
        dial_code: '+387',
        code: 'BA',
        example: 'XXX XXX XXX',
        max_length: 9,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg',
    },
    {
        name: 'Bulgaria',
        dial_code: '+359',
        code: 'BG',
        example: 'XXXX XXX XXX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg',
    },
    {
        name: 'Croatia',
        dial_code: '+385',
        code: 'HR',
        example: 'XXX XXX XXXX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg',
    },
    {
        name: 'Cyprus',
        dial_code: '+357',
        code: 'CY',
        example: 'XX XXXXXX',
        max_length: 8,
        min_length: 8,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg',
    },
    {
        name: 'Czech Republic',
        dial_code: '+420',
        code: 'CZ',
        example: 'XXX XXX XXX',
        max_length: 9,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg',
    },
    {
        name: 'Denmark',
        dial_code: '+45',
        code: 'DK',
        example: 'XX XX XX XX',
        max_length: 8,
        min_length: 8,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg',
    },
    {
        name: 'Estonia',
        dial_code: '+372',
        code: 'EE',
        example: 'XXX XXXX',
        max_length: 7,
        min_length: 7,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg',
    },
    {
        name: 'Finland',
        dial_code: '+358',
        code: 'FI',
        example: 'XXX XXXXXXX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg',
    },
    {
        name: 'France',
        dial_code: '+33',
        code: 'FR',
        example: 'XX XX XX XX XX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg',
    },
    {
        name: 'Georgia',
        dial_code: '+995',
        code: 'GE',
        example: 'XXX XX XX XX',
        max_length: 9,
        min_length: 8,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg',
    },
    {
        name: 'Germany',
        dial_code: '+49',
        code: 'DE',
        example: 'XXXXX XXXXXXX',
        max_length: 11,
        min_length: 10,
        flag: 'https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg',
    },
    {
        name: 'Greece',
        dial_code: '+30',
        code: 'GR',
        example: 'XXX XXX XXXX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg',
    },
    {
        name: 'Hungary',
        dial_code: '+36',
        code: 'HU',
        example: 'XX XX XXX XXXX',
        max_length: 11,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg',
    },
    {
        name: 'Iceland',
        dial_code: '+354',
        code: 'IS',
        example: 'XXX XXXX',
        max_length: 7,
        min_length: 7,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg',
    },
    {
        name: 'Ireland',
        dial_code: '+353',
        code: 'IE',
        example: 'XXX XXX XXXX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg',
    },
    {
        name: 'Italy',
        dial_code: '+39',
        code: 'IT',
        example: 'XXX XXX XXXX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg',
    },
    {
        name: 'Kazakhstan',
        dial_code: '+77',
        code: 'KZ',
        example: 'X XXX XXX XXXX',
        max_length: 12,
        min_length: 10,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg',
    },
    {
        name: 'Kyrgyzstan',
        dial_code: '+996',
        code: 'KG',
        example: 'XXXX XXX XXX',
        max_length: 9,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg',
    },
    {
        name: 'Latvia',
        dial_code: '+371',
        code: 'LV',
        example: 'XX XXX XXX',
        max_length: 8,
        min_length: 8,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg',
    },
    {
        name: 'Lithuania',
        dial_code: '+370',
        code: 'LT',
        example: 'XXX XXXXX',
        max_length: 8,
        min_length: 8,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg',
    },
    {
        name: 'Luxembourg',
        dial_code: '+352',
        code: 'LU',
        example: 'XXX XXX XXX',
        max_length: 9,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Luxembourg.svg',
    },
    {
        name: 'Macedonia',
        dial_code: '+389',
        code: 'MK',
        example: 'XXX XXX XXX',
        max_length: 9,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/f/f8/Flag_of_Macedonia.svg',
    },
    {
        name: 'Malta',
        dial_code: '+356',
        code: 'MT',
        example: 'XXXX XXXX',
        max_length: 8,
        min_length: 8,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg',
    },
    {
        name: 'Moldova',
        dial_code: '+373',
        code: 'MD',
        example: 'XXXX XX XXX',
        max_length: 9,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Moldova.svg',
    },
    {
        name: 'Monaco',
        dial_code: '+377',
        code: 'MC',
        example: 'XX XX XX XX XX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Monaco.svg',
    },
    {
        name: 'Montenegro',
        dial_code: '+382',
        code: 'ME',
        example: 'XXX XXX XXX',
        max_length: 9,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Montenegro.svg',
    },
    {
        name: 'Netherlands',
        dial_code: '+31',
        code: 'NL',
        example: 'XX XXXXXXXX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg',
    },
    {
        name: 'Norway',
        dial_code: '+47',
        code: 'NO',
        example: 'XXX XX XXX',
        max_length: 9,
        min_length: 8,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg',
    },
    {
        name: 'Poland',
        dial_code: '+48',
        code: 'PL',
        example: 'XXX XXX XXX',
        max_length: 9,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/en/1/12/Flag_of_Poland.svg',
    },
    {
        name: 'Portugal',
        dial_code: '+351',
        code: 'PT',
        example: 'XXX XXX XXX',
        max_length: 9,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg',
    },
    {
        name: 'Republic of Kosovo',
        dial_code: '+383',
        code: 'XK',
        example: 'XXX XXX XXX',
        max_length: 9,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/1/1f/Flag_of_Kosovo.svg',
    },
    {
        name: 'Romania',
        dial_code: '+40',
        code: 'RO',
        example: 'XXXX XXX XXX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg',
    },
    {
        name: 'Serbia',
        dial_code: '+381',
        code: 'RS',
        example: 'XXX XXX XXXX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg',
    },
    {
        name: 'Slovakia',
        dial_code: '+421',
        code: 'SK',
        example: 'XXXX XXX XXX',
        max_length: 9,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg',
    },
    {
        name: 'Slovenia',
        dial_code: '+386',
        code: 'SI',
        example: 'XXX XXX XXX',
        max_length: 9,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg',
    },
    {
        name: 'Spain',
        dial_code: '+34',
        code: 'ES',
        example: 'XXX XX XX XX',
        max_length: 9,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg',
    },
    {
        name: 'Sweden',
        dial_code: '+46',
        code: 'SE',
        example: 'XXX XXX XX XX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/en/4/4c/Flag_of_Sweden.svg',
    },
    {
        name: 'Switzerland',
        dial_code: '+41',
        code: 'CH',
        example: 'XXX XXX XX XX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/0/08/Flag_of_Switzerland_%28Pantone%29.svg',
    },
    {
        name: 'Tajikistan',
        dial_code: '+992',
        code: 'TJ',
        example: 'XXX XX XX XX',
        max_length: 9,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Tajikistan.svg',
    },
    {
        name: 'Turkmenistan',
        dial_code: '+993',
        code: 'TM',
        example: 'X XX XXX XXXX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Turkmenistan.svg',
    },
    {
        name: 'Ukraine',
        dial_code: '+380',
        code: 'UA',
        example: 'XXX XXX XX XX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg',
    },
    // {
    //   name: 'United Kingdom',
    //   dial_code: '+44',
    //   code: 'GB',
    //   example: 'XXXXX XXXXXX',
    //   max_length: 11,
    //   min_length: 10,
    //   flag: 'https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg',
    // },
    {
        name: 'Uzbekistan',
        dial_code: '+998',
        code: 'UZ',
        example: 'X XX XXX XX XX',
        max_length: 10,
        min_length: 9,
        flag: 'https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg',
    },
];
export const SUPPORTED_CALLING_CODES = [
    { name: 'Albania', dial_code: '+355', code: 'AL', example: 'XXX XX XXX XX' },
    { name: 'Armenia', dial_code: '+374', code: 'AM', example: 'XXX XX XX XX' },
    { name: 'Austria', dial_code: '+43', code: 'AT', example: 'XXXX XXXXXX' },
    { name: 'Azerbaijan', dial_code: '+994', code: 'AZ', example: 'XXX XXX XX XX' },
    { name: 'Belarus', dial_code: '+375', code: 'BY', example: 'X XXX XXX XX XX' },
    { name: 'Belgium', dial_code: '+32', code: 'BE', example: 'XXXX XX XX XX' },
    { name: 'Bosnia and Herzegovina', dial_code: '+387', code: 'BA', example: 'XXX XXX XXX' },
    { name: 'Bulgaria', dial_code: '+359', code: 'BG', example: 'XXXX XXX XXX' },
    { name: 'Croatia', dial_code: '+385', code: 'HR', example: 'XXX XXX XXXX' },
    { name: 'Cyprus', dial_code: '+357', code: 'CY', example: 'XX XXXXXX' },
    { name: 'Czech Republic', dial_code: '+420', code: 'CZ', example: 'XXX XXX XXX' },
    { name: 'Denmark', dial_code: '+45', code: 'DK', example: 'XX XX XX XX' },
    { name: 'Estonia', dial_code: '+372', code: 'EE', example: 'XXX XXXX' },
    { name: 'Finland', dial_code: '+358', code: 'FI', example: 'XXX XXXXXXX' },
    { name: 'France', dial_code: '+33', code: 'FR', example: 'XX XX XX XX XX' },
    { name: 'Georgia', dial_code: '+995', code: 'GE', example: 'XXX XX XX XX' },
    { name: 'Germany', dial_code: '+49', code: 'DE', example: 'XXXXX XXXXXXX' },
    { name: 'Greece', dial_code: '+30', code: 'GR', example: 'XXX XXX XXXX' },
    { name: 'Hungary', dial_code: '+36', code: 'HU', example: 'XX XX XXX XXXX' },
    { name: 'Iceland', dial_code: '+354', code: 'IS', example: 'XXX XXXX' },
    { name: 'Ireland', dial_code: '+353', code: 'IE', example: 'XXX XXX XXXX' },
    { name: 'Italy', dial_code: '+39', code: 'IT', example: 'XXX XXX XXXX' },
    { name: 'Kazakhstan', dial_code: '+77', code: 'KZ', example: 'X XXX XXX XXXX' },
    { name: 'Kyrgyzstan', dial_code: '+996', code: 'KG', example: 'XXXX XXX XXX' },
    { name: 'Latvia', dial_code: '+371', code: 'LV', example: 'XX XXX XXX' },
    { name: 'Lithuania', dial_code: '+370', code: 'LT', example: 'XXX XXXXX' },
    { name: 'Luxembourg', dial_code: '+352', code: 'LU', example: 'XXX XXX XXX' },
    { name: 'Macedonia', dial_code: '+389', code: 'MK', example: 'XXX XXX XXX' },
    { name: 'Malta', dial_code: '+356', code: 'MT', example: 'XXXX XXXX' },
    { name: 'Moldova', dial_code: '+373', code: 'MD', example: 'XXXX XX XXX' },
    { name: 'Monaco', dial_code: '+377', code: 'MC', example: 'XX XX XX XX XX' },
    { name: 'Montenegro', dial_code: '+382', code: 'ME', example: 'XXX XXX XXX' },
    { name: 'Netherlands', dial_code: '+31', code: 'NL', example: 'XX XXXXXXXX' },
    { name: 'Norway', dial_code: '+47', code: 'NO', example: 'XXX XX XXX' },
    { name: 'Poland', dial_code: '+48', code: 'PL', example: 'XXX XXX XXX' },
    { name: 'Portugal', dial_code: '+351', code: 'PT', example: 'XXX XXX XXX' },
    { name: 'Republic of Kosovo', dial_code: '+383', code: 'XK', example: 'XXX XXX XXX' },
    { name: 'Romania', dial_code: '+40', code: 'RO', example: 'XXXX XXX XXX' },
    { name: 'Serbia', dial_code: '+381', code: 'RS', example: 'XXX XXX XXXX' },
    { name: 'Slovakia', dial_code: '+421', code: 'SK', example: 'XXXX XXX XXX' },
    { name: 'Slovenia', dial_code: '+386', code: 'SI', example: 'XXX XXX XXX' },
    { name: 'Spain', dial_code: '+34', code: 'ES', example: 'XXX XX XX XX' },
    { name: 'Sweden', dial_code: '+46', code: 'SE', example: 'XXX XXX XX XX' },
    { name: 'Switzerland', dial_code: '+41', code: 'CH', example: 'XXX XXX XX XX' },
    { name: 'Tajikistan', dial_code: '+992', code: 'TJ', example: 'XXX XX XX XX' },
    { name: 'Turkmenistan', dial_code: '+993', code: 'TM', example: 'X XX XXX XXXX' },
    { name: 'Ukraine', dial_code: '+380', code: 'UA', example: 'XXX XXX XX XX' },
    // { name: 'United Kingdom', dial_code: '+44', code: 'GB', example: 'XXXXX XXXXXX' },
    { name: 'Uzbekistan', dial_code: '+998', code: 'UZ', example: 'X XX XXX XX XX' },
];
// export const PHONE_REGEX = /^(\([0-9]{3}\)\s*|[0-9]{3}\-)[0-9]{3}-[0-9]{4}$/;
// const filterCountries = () => {
//   //loop thourgh SUPPORTED_COUNTRIES and return only the country object that matches the country code
//   const filteredArray = [];
//   const unfilteredSupportedCountries = [];
//   for (let i = 0; i < COUNTRY_CALLING_CODES.length; i++) {
//     for (let j = 0; j < SUPPORTED_COUNTRIES.length; j++) {
//       if (COUNTRY_CALLING_CODES[i].code === SUPPORTED_COUNTRIES[j]) {
//         filteredArray.push(COUNTRY_CALLING_CODES[i]);
//       }
//     }
//   }
//   console.log(filteredArray);
//   return filteredArray;
// };
// filterCountries();
export const companyAndMarketsSchema = yup.object({
    numberOfEmployees: yup.string().required('validations.required-no-of-employees'),
    numberOfSalesPerson: yup
        .string()
        .when('numberOfEmployees', (numberOfEmployees, schema) => {
        return schema.test({
            test: (numberOfSalesPerson) => {
                let noOfEmp = numberOfEmployees.split('-');
                noOfEmp = noOfEmp.length === 2 ? noOfEmp[1] : noOfEmp[0];
                noOfEmp = +noOfEmp.replace(/[^0-9]/, '');
                let noOfSp = numberOfSalesPerson.split('-');
                noOfSp = noOfSp.length === 2 ? noOfSp[1] : noOfSp[0];
                noOfSp = +noOfSp.replace(/[^0-9]/, '');
                return !numberOfEmployees || noOfSp <= noOfEmp;
            },
            message: 'Number of sales persons can not be greater than number of employees',
        });
    })
        .required('validations.required-no-of-sales-person'),
    companyLocations: yup.array().of(yup.string()).required('validations.required-company-locations'),
});
export const portfolioSchema = yup.object({
    distributingBrands: yup.boolean().required(),
    brands: yup.string().when('distributingBrands', {
        is: true,
        then: yup.string().max(4000).required(),
    }),
    offeringEducationalServices: yup.boolean().required(),
    educationServices: yup.array().when('offeringEducationalServices', {
        is: true,
        then: yup.array().of(yup.string()).required(),
    }),
});
export const salonAndPortfolioSchema = yup.object({
    numberOfEmployees: yup.string().required('validations.required-no-of-employees'),
    techniquesAndServices: yup
        .array()
        .of(yup.string())
        .required('validations.required-education-services'),
    // interestedInEducation: yup.boolean().required(),
    // companyLocations: yup.string().required(),
});
