import { Context } from '@/interfaces/application';
import { sendRequest } from '@/lib/request';
import { serviceTypeNames } from '@/lib/service';
import { defineStore } from 'pinia';
import { useCartStore } from './cart.module';
export const usePaymentStore = defineStore('paymentModule', {
    state: () => ({
        gateways: {
            total: 0,
            gatewayList: [],
        },
        methods: {
            total: null,
            paymentMethodList: [],
        },
        salesOrderUniqueId: '',
        savePaymentMethod: false,
        checkoutId: '',
        url: '',
        paymentGatewayUniqueId: '',
        selectedPaymentGateway: {},
        paymentMethodUniqueId: null,
        lastPaymentMethodUsed: '',
        purchaseSuccessful: false,
        selectedPaymentMethod: { method: '', remarks: '', charges: 0 },
        paymentUniqueId: null,
    }),
    actions: {
        SET_SELECTED_PAYMENT_GATEWAY(id) {
            if (id) {
                this.selectedPaymentGateway = this.gateways.gatewayList.find((item) => {
                    return item.paymentGatewayUniqueId == id;
                });
            }
            else {
                this.selectedPaymentGateway = {};
            }
        },
        SET_SELECTED_PAYMENT_METHOD(method) {
            this.selectedPaymentMethod = method;
        },
        CLEAR_PAYMENT_STORE() {
            this.gateways.gatewayList = [];
            this.methods.paymentMethodList = [];
            this.salesOrderUniqueId = '';
            this.paymentGatewayUniqueId = '';
            this.checkoutId = '';
            this.url = '';
            this.lastPaymentMethodUsed = this.paymentMethodUniqueId;
            this.paymentMethodUniqueId = null;
            this.savePaymentMethod = false;
            this.purchaseSuccessful = false;
            this.selectedPaymentMethod = { method: '', remarks: '', charges: 0 };
        },
        async MAKE_PAYMENT(savePaymentMethod = true) {
            const cartStore = useCartStore();
            if (!this.methods.paymentMethodList.length) {
                await this.PAYMENT_METHOD_LIST({
                    filter: {},
                    order: {},
                    page: 1,
                    itemsPerPage: 5,
                    limit: null,
                    search: {},
                });
            }
            if (!this.gateways.gatewayList.length) {
                await this.PAYMENT_GATEWAY_LIST({
                    filter: {},
                    order: {},
                    page: 1,
                    itemsPerPage: 5,
                    limit: null,
                });
            }
            const paymentGatewayUniqueId = this.paymentGatewayUniqueId;
            if (!this.checkoutId) {
                const PaymentStartResponse = await this.PAYMENT_START({
                    salesOrderUniqueId: null,
                    cartUniqueId: cartStore.cartUniqueId,
                    paymentMethodUniqueId: null,
                    paymentMethodSave: savePaymentMethod,
                    paymentGatewayUniqueId: paymentGatewayUniqueId,
                    paymentGatewayData: {},
                });
                this.checkoutId = PaymentStartResponse?.paymentFormData.checkoutId;
                this.url = PaymentStartResponse?.paymentFormData.url;
                this.paymentUniqueId = PaymentStartResponse?.paymentUniqueId;
            }
            const returnResponse = {
                gatewayList: this.gateways.gatewayList,
                salesOrderUniqueId: cartStore.salesOrderUniqueId,
                paymentGatewayUniqueId: this.paymentGatewayUniqueId,
                checkoutId: this.checkoutId,
                url: this.url,
                paymentMethodUniqueId: this.paymentMethodUniqueId,
            };
            return returnResponse;
        },
        async PAYMENT_GATEWAY_LIST(payload) {
            try {
                const res = await sendRequest(serviceTypeNames.PAYMENT_GATEWAY_LIST, payload, undefined, '', Context.Account);
                this.gateways.gatewayList = res.list;
                this.gateways.total = res.total;
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_GATEWAY_GET(payload) {
            try {
                const res = await sendRequest(serviceTypeNames.PAYMENT_GATEWAY_GET, payload);
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_METHOD_LIST(payload) {
            try {
                const res = await sendRequest(serviceTypeNames.PAYMENT_METHOD_LIST, payload);
                this.methods.paymentMethodList = res.list;
                this.methods.total = res.total;
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_METHOD_GET(payload) {
            try {
                const res = await sendRequest(serviceTypeNames.PAYMENT_METHOD_GET, payload);
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_METHOD_CREATE(payload) {
            try {
                const res = await sendRequest(serviceTypeNames.PAYMENT_METHOD_CREATE, payload, undefined, 'Payment method added successfully');
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_METHOD_SET_DEFAULT(payload) {
            try {
                const res = await sendRequest(serviceTypeNames.PAYMENT_METHOD_SET_DEFAULT, payload, undefined, 'Default payment method changed successfully');
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_INIT() {
            const payload = {
                paymentGatewayUniqueId: this.paymentGatewayUniqueId,
                paymentGatewayData: {},
            };
            try {
                const res = await sendRequest(serviceTypeNames.SCENARIO_SG10_X00029, payload);
                return res.paymentData;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_START(payload) {
            try {
                const res = await sendRequest(serviceTypeNames.SCENARIO_SG10_X00030, payload);
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_CONFIRM(payload) {
            const cartStore = useCartStore();
            try {
                const res = await sendRequest(serviceTypeNames.SCENARIO_SG10_X00032, payload);
                cartStore.cartUniqueId = null;
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async PAYMENT_CREATE(payload) {
            try {
                const res = await sendRequest(serviceTypeNames.SCENARIO_SG10_X00031, payload);
                this.paymentGatewayUniqueId = res.paymentGatewayUniqueId;
                this.salesOrderUniqueId = res.salesOrderUniqueId;
                this.purchaseSuccessful = res.purchaseSuccessful;
                this.paymentUniqueId = res.paymentUniqueId;
                return res;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
    },
    persist: {
        paths: [
            'gatewayList',
            'salesOrderUniqueId',
            'savePaymentMethod',
            'paymentGatewayUniqueId',
            'checkoutId',
            'url',
            'lastPaymentMethodUsed',
            'paymentUniqueId',
        ],
    },
});
