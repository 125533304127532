import { PASSWORD_REGEX } from "@/utils/constants";
import * as yup from "yup";
// usage:
// const merged = merge(schema1, schema2, schema3, etc);
export const getEmailSchema = () => yup.string().trim().required().email().max(50);
export const getPasswordSchema = () => yup.string().trim().required();
export const getPassswordRepeatSchema = () => yup
    .string()
    .trim()
    .required()
    .oneOf([yup.ref("password"), null], "validations.passwords-match");
// export const emailSchema = yup.string().required().email();
// export const passwordSchema = yup.string().required();
export const getPasswordRepeatSchema = () => {
    return yup
        .string()
        .required()
        .oneOf([yup.ref("password"), null], "validations.passwords-match").max(50);
};
export const getCredsSchema = () => {
    return {
        email: getEmailSchema(),
        password: getPasswordSchema().matches(PASSWORD_REGEX, "validations.password").max(50),
    };
};
export const getCredsSchemaLogin = () => {
    return {
        email: getEmailSchema(),
        password: getPasswordSchema(),
    };
};
