import App from '@/App.vue';
import 'maz-ui/css/main.css';
import '@/assets/cartzilla/maz-ui.css';
import '@/assets/cartzilla/theme.scss';
import i18n from '@/i18n';
import getCookie from '@/lib/get-cookie';
import router from '@/router';
import { library } from '@fortawesome/fontawesome-svg-core';
import 'vue-multiselect/dist/vue-multiselect.css';
import axios from 'axios';
import { faCircleCheck as faCircleCheckReg, faMessage, faSquare, faComment } from '@fortawesome/free-regular-svg-icons';
import { faArrowsLeftRight, faArrowUpRightFromSquare, faAward, faBell, faCartShopping, faCheckCircle, faCheckSquare, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCircleCheck, faCircleQuestion, faCreditCard, faEdit, faEllipsis, faEnvelope, faExclamationTriangle, faFilter, faHeart, faHome, faInfoCircle, faLanguage, faLaptopFile, faMoneyBill, faPhone, faPlus, faSchool, faSearch, faShield, faSort, faSortDown, faSortUp, faSyncAlt, faTimes, faTrash, faTruckFast, faUser, faUserAlt, faUserCircle, faUserGraduate, faXmark, faUserClock, faUserCheck, faCheck, faPaperclip, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import { createApp, markRaw } from 'vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css';
import VueGtag from 'vue-gtag';
import VueLazyLoad from 'vue-lazyload';
import LoadScript from 'vue-plugin-load-script';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueSocialSharing from 'vue-social-sharing';
import VueClipboard from 'vue3-clipboard';
import { setLocale } from 'yup';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
library.add(faUserClock, faUserCheck, faUserGraduate, faUserAlt, faCircleCheck, faAward, faLanguage, faCircleQuestion, faArrowUpRightFromSquare, faBell, faHome, faCreditCard, faMoneyBill, faShield, faUser, faLaptopFile, faSyncAlt, faPlus, faEllipsis, faSquare, faCartShopping, faTruckFast, faCreditCard, faCheckCircle, faUserCircle, faTimes, faCheckSquare, faChevronDown, faChevronUp, faArrowsLeftRight, faFilter, faSearch, faChevronLeft, faChevronRight, faHeart, faExclamationTriangle, faInfoCircle, faSortDown, faSortUp, faSort, faXmark, faTrash, faEdit, faSchool, faPhone, faEnvelope, faCircleCheckReg, faMessage, faCheck, faPaperclip, faComment, faTruckFast);
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, async function (error) {
    if (error.response && error.response.data && error.response.data.statusCode === 401) {
        const userStore = useUserStore();
        await userStore.LOGOUT();
        router.replace('/');
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
const app = createApp(App);
app.component('MazPhoneNumberInput', MazPhoneNumberInput);
app.use(VueDOMPurifyHTML);
app.use(LoadScript);
app.use(VueSocialSharing, {
    networks: {
        instagram: 'https://instagram.com/share?url=@url&title=@title',
    },
});
setLocale({
    mixed: {
        required: 'validations.required',
    },
    string: {
        min: ({ min }) => `validations.min|${JSON.stringify({ min })}`,
        max: ({ max }) => `validations.max|${JSON.stringify({ max })}`,
        email: 'validations.email',
    },
    number: {
        positive: 'validations.positive',
    },
});
// Filters
app.config.globalProperties.$filters = {
    kebabize: (str) => {
        const whitelist = ['.', '-'];
        return str
            .split('')
            .map((letter, idx) => {
            if (whitelist.includes(letter)) {
                return letter;
            }
            return letter.toUpperCase() === letter
                ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
                : letter;
        })
            .join('');
    },
    currency: (amount) => {
        let locale = 'en-US';
        let currency = 'EUR';
        const cookieLocale = getCookie('locale', false);
        const cookieCurrency = getCookie('currency');
        if (cookieLocale) {
            locale = cookieLocale;
        }
        if (cookieCurrency) {
            currency = cookieCurrency;
        }
        // Create our number formatter.
        const formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
        });
        return formatter.format(amount); /* $2,500.00 */
    },
};
// Directives
app.directive('truncate', {
    mounted(el, binding) {
        if (!binding.value)
            return '';
        if (!binding.arg || binding.value.length <= binding.arg)
            return binding.value;
        el.textContent = binding.value.slice(0, binding.arg) + '...';
    },
    updated(el, binding) {
        if (!binding.value)
            return '';
        if (!binding.arg || binding.value.length <= binding.arg)
            return binding.value;
        el.textContent = binding.value.slice(0, +binding.arg) + '...';
    },
});
//Google Analytics and ReCAPTCHA V3
const GA_MEASUREMENT_ID = process.env.VUE_APP_ANALYTICS_GOOGLE_ANALYTICS_GTAG_ID;
app.use(VueGtag, {
    bootstrap: false,
    // disableScriptLoad: true,
});
app.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_V3_KEY,
    loaderOptions: {
        useRecaptchaNet: true,
        useEnterprise: true,
        autoHideBadge: true,
    },
});
app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
});
import loadingImage from './assets/img/loading-hd-260x260.gif';
import errorImage from './assets/img/image_not found-01.webp';
import { useUserStore } from './store/modules/service/user.module';
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(({ store }) => {
    store.router = markRaw(router);
});
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(VueLazyLoad, {
    error: errorImage,
    loading: loadingImage,
});
//work
app.use(pinia);
app.use(i18n);
app.use(router);
app.use(FloatingVue);
app.mount('#app');
