import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/store/modules/service/user.module';
import CmMainLayout from '@/layout/cm-main-layout.vue';
import CmAuthLayout from '@/layout/cm-auth-layout.vue';
import CmNoLayout from '@/layout/cm-no-layout.vue';
import { UserType } from '@/interfaces/user-options';
import { useProductStore } from '@/store/modules/service/product.module';
export const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/view-home.vue'),
        meta: {
            layout: CmMainLayout,
            paddingTop: false,
            paddingBottom: false,
            requiresAuth: false,
            type: 'navbar1',
            title: { key: 'routes.home', choice: null },
            breadcrumb: [{ name: { key: 'home', choice: null } }],
            pageTitle: 'Seamless1 | Your Platform To Grow | Seamless1, Peptame',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/account',
        name: 'account',
        component: () => import(/* webpackChunkName: "my-account" */ '../views/my-account/view-my-account.vue'),
        meta: {
            paddingTop: false,
            layout: CmMainLayout,
            requiresAuth: true,
            type: 'navbar1',
            title: { key: 'routes.login', choice: null },
            breadcrumb: [
                { path: '/', text: 'Home' },
                { path: '/account', text: 'Dashboard' },
            ],
            pageTitle: 'Profile | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
        children: [
            {
                path: '',
                component: () => import(
                /* webpackChunkName: "my-account-dashboard" */ '../views/my-account/view-dashboard.vue'),
                name: 'my-account-dashboard',
                meta: {
                    title: 'Dashboard',
                    breadcrumb: [
                        // { path: '/account', text: 'Home' },
                        { path: '/account', text: 'Account' },
                    ],
                    pageTitle: 'Profile / Dashboard | Seamless1',
                    pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                },
            },
            {
                path: 'purchases',
                redirect: '/account/purchases/subscriptions',
                component: () => import(
                /* webpackChunkName: "my-account-purchases" */ '../views/my-account/view-purchases.vue'),
                name: 'my-account-purchases',
                meta: {
                    title: 'Purchases',
                    breadcrumb: [
                        // { path: '/account', text: 'Home' },
                        { path: '/account', text: 'Account' },
                        { path: '/account/purchases', text: 'Purchases' },
                    ],
                    pageTitle: 'Profile / Subscriptions | Seamless1',
                    pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                },
                children: [
                    {
                        path: 'subscriptions',
                        name: 'my-account-subscriptions',
                        component: () => import(
                        /* webpackChunkName: "my-account-subscriptions" */ '../views/my-account/purchases/view-subscriptions.vue'),
                        meta: {
                            breadcrumb: [
                                // { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/purchases', text: 'Purchases' },
                                {
                                    path: '/account/purchases/subscriptions',
                                    text: 'Subscriptions',
                                },
                            ],
                            pageTitle: 'Profile / Subscriptions | Seamless1',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                    {
                        path: 'vouchers',
                        name: 'my-account-vouchers',
                        component: () => import(
                        /* webpackChunkName: "my-account-vouchers" */ '../views/my-account/purchases/view-vouchers.vue'),
                        meta: {
                            breadcrumb: [
                                // { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/purchases', text: 'Purchases' },
                                { path: '/account/purchases/vouchers', text: 'Vouchers' },
                            ],
                            pageTitle: 'Profile / Vouchers | Seamless1',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                ],
            },
            {
                path: 'billing',
                redirect: '/account/billing/orders',
                component: () => import(
                /* webpackChunkName: "my-account-billing" */ '../views/my-account/view-billing.vue'),
                name: 'my-account-billing',
                meta: {
                    title: 'Billing',
                    breadcrumb: [
                        // { path: '/account', text: 'Home' },
                        { path: '/account', text: 'Account' },
                        { path: '/accoun/billing', text: 'Billing' },
                    ],
                    pageTitle: 'Profile / Billing | Seamless1',
                },
                children: [
                    {
                        path: 'orders',
                        component: () => import(
                        /* webpackChunkName: "my-account-orders" */ '../views/my-account/billing/account-orders.vue'),
                        name: 'my-account-orders',
                        meta: {
                            title: 'Orders',
                            breadcrumb: [
                                // { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/billing', text: 'Billing' },
                                { path: '/account/billing/orders', text: 'Orders' },
                            ],
                            pageTitle: 'Profile / Orders | Seamless1',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                    {
                        path: 'returns',
                        component: () => import(
                        /* webpackChunkName: "my-account-orders" */ '../views/my-account/billing/account-returns.vue'),
                        name: 'my-account-returns',
                        meta: {
                            title: 'Returns',
                            breadcrumb: [
                                // { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/billing', text: 'Billing' },
                                { path: '/account/billing/returns', text: 'Returns' },
                            ],
                            pageTitle: 'Profile / Returns | Seamless1',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                    {
                        path: 'payment-methods',
                        component: () => import(
                        /* webpackChunkName: "my-account-payment" */ '../views/my-account/billing/account-payments.vue'),
                        name: 'my-account-payments',
                        meta: {
                            title: 'Payments',
                            breadcrumb: [
                                // { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/billing', text: 'Billing' },
                                {
                                    path: '/account/billing/payment-methods',
                                    text: 'Payment methods',
                                },
                            ],
                            pageTitle: 'Profile / Payment methods | Seamless1',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                    {
                        path: 'credits',
                        component: () => import(
                        /* webpackChunkName: "my-account-credits" */ '../views/my-account/billing/account-credits.vue'),
                        name: 'my-account-credits',
                        meta: {
                            title: 'Credits',
                            breadcrumb: [
                                // { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/billing', text: 'Billing' },
                                {
                                    path: '/account/billing/credits',
                                    text: 'credit',
                                    choice: 2,
                                },
                            ],
                            pageTitle: 'Profile / Credits | Seamless1',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                    {
                        path: 'ledgers',
                        component: () => import(
                        /* webpackChunkName: "my-account-ledgers" */ '../views/my-account/billing/account-ledgers.vue'),
                        name: 'my-account-ledgers',
                        meta: {
                            title: 'Ledgers',
                            breadcrumb: [
                                // { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/billing', text: 'Billing' },
                                {
                                    path: '/account/billing/ledgers',
                                    text: 'Ledger',
                                    choice: 2,
                                },
                            ],
                            pageTitle: 'Profile / Ledgers | Seamless1',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                ],
            },
            {
                path: 'notifications',
                redirect: '/account/notifications/inbox',
                component: () => import(
                /* webpackChunkName: "my-account-notifications" */ '../views/my-account/view-notifications.vue'),
                name: 'my-account-notifications',
                meta: {
                    title: 'Notifications',
                    breadcrumb: [
                        // { path: '/account', text: 'Home' },
                        { path: '/account', text: 'Account' },
                        { path: '/account/notifications', text: 'Notifications' },
                    ],
                    pageTitle: 'Profile / Notifications | Toptellers',
                    pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                },
                children: [
                    {
                        path: 'inbox',
                        component: () => import(
                        /* webpackChunkName: "my-account-notifications" */ '../views/my-account/notifications/view-inbox.vue'),
                        name: 'my-account-inbox',
                        meta: {
                            title: 'Inbox',
                            breadcrumb: [
                                // { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/notifications', text: 'Notifications' },
                                { path: '/account/notifications/inbox', text: 'Inbox' },
                            ],
                            pageTitle: 'Profile / Notifications / Inbox | Toptellers',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    }
                ]
            },
            {
                path: 'security',
                redirect: '/account/security/users',
                component: () => import(
                /* webpackChunkName: "my-account-security" */ '../views/my-account/view-security.vue'),
                name: 'my-account-security',
                meta: {
                    title: 'Security',
                    breadcrumb: [
                        // { path: '/account', text: 'Home' },
                        { path: '/account', text: 'Account' },
                        { path: '/account/security', text: 'Security' },
                    ],
                    pageTitle: 'Profile / Security | Seamless1',
                    pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                },
                children: [
                    {
                        path: 'users',
                        component: () => import(
                        /* webpackChunkName: "my-account-users" */ '../views/my-account/security/view-users.vue'),
                        name: 'my-account-users',
                        meta: {
                            title: 'Users',
                            breadcrumb: [
                                { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/security', text: 'Security' },
                                { path: '/account/security/users', text: 'Users' },
                            ],
                            pageTitle: 'Profile / Users | Seamless1',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                    {
                        path: 'password-change',
                        component: () => import(
                        /* webpackChunkName: "my-account-password-change" */ '../views/my-account/security/view-password-change.vue'),
                        name: 'my-account-password-change',
                        meta: {
                            title: 'Password Change',
                            breadcrumb: [
                                { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/security', text: 'Security' },
                                {
                                    path: '/account/security/password-change',
                                    text: 'Password Change',
                                },
                            ],
                            pageTitle: 'Profile / Password Change | Seamless1',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                ],
            },
            {
                redirect: '/account/profile/personal-information',
                path: 'profile',
                component: () => import(
                /* webpackChunkName: "my-account-profile" */ '../views/my-account/view-profile.vue'),
                name: 'my-account-profile',
                meta: {
                    title: 'Profile',
                    breadcrumb: [
                        // { path: '/account', text: 'Home' },
                        { path: '/account', text: 'Account' },
                        { path: '/account/profile', text: 'Profile' },
                    ],
                    pageTitle: 'Profile / Personal Information | Seamless1',
                    pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                },
                children: [
                    {
                        path: 'personal-information',
                        component: () => import(
                        /* webpackChunkName: "my-account-personal-information" */ '../views/my-account/profile/view-personal-information.vue'),
                        name: 'my-account-personal-information',
                        meta: {
                            title: 'Personal Information',
                            breadcrumb: [
                                // { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/profile', text: 'Profile' },
                                {
                                    path: '/account/profile/personal-information',
                                    text: 'Personal Information',
                                },
                            ],
                            pageTitle: 'Profile / Personal Information | Seamless1',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                    {
                        path: 'organization',
                        component: () => import(
                        /* webpackChunkName: "my-account-organization" */ '../views/my-account/profile/view-organization.vue'),
                        name: 'my-account-organization',
                        meta: {
                            title: 'Organization',
                            breadcrumb: [
                                // { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/profile', text: 'Profile' },
                                {
                                    path: '/account/profile/organization',
                                    text: 'Organization',
                                },
                            ],
                            pageTitle: 'Profile / Organization | Seamless1',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                    // {
                    //   path: 'company-and-markets',
                    //   component: () =>
                    //     import(
                    //       /* webpackChunkName: "my-account-organization" */ '../views/my-account/profile/view-company-markets.vue'
                    //     ),
                    //   name: 'my-account-company-and-markets',
                    //   meta: {
                    //     title: 'Company and Markets',
                    //     breadcrumb: [
                    //       // { path: '/account', text: 'Home' },
                    //       { path: '/account', text: 'Account' },
                    //       { path: '/account/profile', text: 'Profile' },
                    //       {
                    //         path: '/account/profile/company-and-markets',
                    //         text: 'Company And Markets',
                    //       },
                    //     ],
                    //     pageTitle: 'Profile / Company and Markets | Seamless1',
                    //     pageDesription:
                    //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                    //   },
                    // },
                    // {
                    //   path: 'portfolio',
                    //   component: () =>
                    //     import(
                    //       /* webpackChunkName: "my-account-portfolio" */ '../views/my-account/profile/view-portfolio.vue'
                    //     ),
                    //   name: 'my-account-portfolio',
                    //   meta: {
                    //     title: 'Portfolio',
                    //     breadcrumb: [
                    //       // { path: '/account', text: 'Home' },
                    //       { path: '/account', text: 'Account' },
                    //       { path: '/account/profile', text: 'Profile' },
                    //       {
                    //         path: '/account/profile/portfolio',
                    //         text: 'Portfolio',
                    //       },
                    //     ],
                    //     pageTitle: 'Profile / Porfolio | Seamless1',
                    //     pageDesription:
                    //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                    //   },
                    // },
                    // {
                    //   path: 'salon-and-portfolio',
                    //   component: () =>
                    //     import(
                    //       /* webpackChunkName: "my-account-salon-and-portfolio" */ '../views/my-account/profile/view-salon-and-portfolio.vue'
                    //     ),
                    //   name: 'my-account-portfolio',
                    //   meta: {
                    //     title: 'Salon And Portfolio',
                    //     breadcrumb: [
                    //       // { path: '/account', text: 'Home' },
                    //       { path: '/account', text: 'Account' },
                    //       { path: '/account/profile', text: 'Profile' },
                    //       {
                    //         path: '/account/profile/salon-and-portfolio',
                    //         text: 'Portfolio',
                    //       },
                    //     ],
                    //     pageTitle: 'Profile / Salon And Porfolio | Seamless1',
                    //     pageDesription:
                    //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                    //   },
                    // },
                    {
                        path: 'addresses',
                        component: () => import(
                        /* webpackChunkName: "my-account-addresses" */ '../views/my-account/profile/view-addresses.vue'),
                        name: 'my-account-addresses',
                        meta: {
                            title: 'Addresses',
                            breadcrumb: [
                                // { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/profile', text: 'Profile' },
                                {
                                    path: '/account/profile/addresses',
                                    text: 'Addresses',
                                },
                            ],
                            pageTitle: 'Profile / Addresses | Seamless1',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                ],
            },
            {
                path: 'favorites',
                redirect: '/account/favorites/favorites',
                name: 'my-account-favorites',
                component: () => import(
                /* webpackChunkName: "my-account-favorites" */ '../views/my-account/view-favorites.vue'),
                meta: {
                    title: 'Favorites',
                    breadcrumb: [
                        // { path: '/account', text: 'Home' },
                        { path: '/account', text: 'Account' },
                        { path: '/account/favorites', text: 'Favorites' },
                    ],
                    pageTitle: 'Profile / Favorites | Seamless1',
                    pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                },
                children: [
                    {
                        path: 'favorites',
                        component: () => import(
                        /* webpackChunkName: "my-account-favorites" */ '../views/my-account/favorites/view-favorites.vue'),
                        name: 'my-account-favorites',
                        meta: {
                            title: 'Favorites',
                            breadcrumb: [
                                // { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/favorites', text: 'Favorites' },
                                { path: '/account/favorites/favorites', text: 'Favorites' },
                            ],
                            pageTitle: 'Profile / Favorites | Seamless1',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                    {
                        path: 'watchlist',
                        component: () => import(
                        /* webpackChunkName: "my-account-watchlist" */ '../views/my-account/favorites/view-watchlist.vue'),
                        name: 'my-account-watchlist',
                        meta: {
                            title: 'Watchlist',
                            breadcrumb: [
                                // { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/favorites', text: 'Favorites' },
                                { path: '/account/favorites/watchlist', text: 'Watchlist' },
                            ],
                            pageTitle: 'Profile / Wishlist | Seamless1',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                    {
                        path: 'purchase-subscription-templates',
                        component: () => import(
                        /* webpackChunkName: "my-account-purchase-subscription-templates" */ '../views/my-account/favorites/view-purchase-subscription-templates.vue'),
                        name: 'my-account-purchase-subscription-templates',
                        meta: {
                            title: 'Templates',
                            breadcrumb: [
                                // { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/favorites', text: 'Favorites' },
                                { path: '/account/favorites/purchase-subscription-templates', text: 'Templates' },
                            ],
                            pageTitle: 'Profile / Templates | Toptellers',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                    // {
                    //   path: 'shortlist',
                    //   component: () =>
                    //     import(
                    //       /* webpackChunkName: "my-account-shortlist" */ '../views/my-account/favorites/view-shortlist.vue'
                    //     ),
                    //   name: 'my-account-shortlist',
                    //   meta: {
                    //     title: 'Shortlist',
                    //     breadcrumb: [
                    //       // { path: '/account', text: 'Home' },
                    //       { path: '/account', text: 'Account' },
                    //       { path: '/account/favorites', text: 'Favorites' },
                    //       { path: '/account/favorites/shortlist', text: 'Shortlist' },
                    //     ],
                    //     pageTitle: 'Profile / Shortlist | Seamless1',
                    //     pageDesription:
                    //       'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                    //   },
                    // },
                ],
            },
            {
                path: 'education',
                redirect: '/account/education/courses',
                component: () => import(
                /* webpackChunkName: "my-account-education" */ '../views/my-account/view-education.vue'),
                name: 'my-account-education',
                meta: {
                    title: 'Education',
                    breadcrumb: [
                        // { path: '/account', text: 'Home' },
                        { path: '/account', text: 'Account' },
                        { path: '/account/education', text: 'Education' },
                    ],
                    pageTitle: 'Profile / Education | Seamless1',
                    pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                },
                children: [
                    {
                        path: 'courses',
                        name: 'my-account-courses',
                        component: () => import(
                        /* webpackChunkName: "my-account-courses" */ '../views/my-account/education/view-courses.vue'),
                        meta: {
                            breadcrumb: [
                                // { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/education', text: 'Education' },
                                { path: '/account/education/courses', text: 'Courses' },
                            ],
                            pageTitle: 'Profile / Courses | Seamless1',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                    {
                        path: 'classes',
                        name: 'my-account-classes',
                        component: () => import(
                        /* webpackChunkName: "my-account-classes" */ '../views/my-account/education/view-classes.vue'),
                        meta: {
                            breadcrumb: [
                                // { path: '/account', text: 'Home' },
                                { path: '/account', text: 'Account' },
                                { path: '/account/education', text: 'Education' },
                                { path: '/account/education/classes', text: 'Classes' },
                            ],
                            pageTitle: 'Profile / Classes | Seamless1',
                            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                        },
                    },
                ],
            },
        ],
    },
    // {
    //   path: "/login",
    //   name: "login",
    //   component: () =>
    //     import(/* webpackChunkName: "login" */ "../views/auth/view-login.vue"),
    //   meta: {
    //     layout: CmAuthLayout,
    //     requiresAuth: false,
    //     title: { key: "routes.login", choice: null },
    //     breadcrumb: [{ name: { key: "login", choice: null } }],
    //     pageTitle: "My Account | Seamless1",
    //   },
    // },
    {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "pre-register" */ '../views/auth/view-register.vue'),
        meta: {
            paddingBottom: false,
            type: 'navbar1',
            layout: CmMainLayout,
            requiresAuth: false,
            title: { key: 'routes.pre-register', choice: null },
            breadcrumb: [{ name: { key: 'pre-register', choice: null } }],
            pageTitle: 'Register | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/post-registration-info',
        name: 'post-registration',
        component: () => import(
        /* webpackChunkName: "post-registration" */ '../views/auth/view-post-registration.vue'),
        meta: {
            // paddingBottom: false,
            type: 'navbar1',
            layout: CmMainLayout,
            requiresAuth: false,
            title: { key: 'routes.pre-register', choice: null },
            breadcrumb: [{ name: { key: 'pre-register', choice: null } }],
            pageTitle: 'Register | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/post-registration-confirmation',
        name: 'post-confirmation',
        component: () => import(
        /* webpackChunkName: "post-confirmation" */ '../views/auth/view-post-confirmation.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            requiresAuth: false,
            title: { key: 'routes.pre-register', choice: null },
            breadcrumb: [{ name: { key: 'pre-register', choice: null } }],
            pageTitle: 'Post Confirmation | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    // {
    //   path: "/post-review",
    //   name: "post-review",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "post-review" */ "../views/auth/view-post-review.vue"
    //     ),
    //   meta: {
    //     type: "navbar1",
    //     layout: CmMainLayout,
    //     requiresAuth: false,
    //     title: { key: "routes.pre-register", choice: null },
    //     breadcrumb: [{ name: { key: "pre-register", choice: null } }],
    //     pageTitle: "Post Review | Seamless1",
    //   },
    // },
    {
        path: '/confirm-user/:token',
        name: 'confirm-user',
        component: () => import(/* webpackChunkName: "confirm-user" */ '../views/auth/view-confirm-user.vue'),
        meta: {
            neverShowCookieBanner: true,
            paddingBottom: false,
            type: 'navbar1',
            layout: CmNoLayout,
            requiresAuth: false,
            title: { key: 'routes.pre-register', choice: null },
            breadcrumb: [{ name: { key: 'pre-register', choice: null } }],
            pageTitle: 'Confirm User | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
            hideFooter: true,
        },
    },
    {
        path: '/delivery-status',
        name: 'delivery-status',
        component: () => import(
        /* webpackChunkName: "delivery-status" */ '../views/view-delivery-status.vue'),
        meta: {
            layout: CmMainLayout,
            requiresAuth: false,
            title: { key: 'routes.delivery-status', choice: null },
            breadcrumb: [{ name: { key: 'delivery-status', choice: null } }],
            pageTitle: 'Delivery Status | Top Tellers',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
            type: 'navbar2',
            // paddingTop: false,
            // paddingBottom: false,
        },
    },
    {
        path: '/become-a-distributor',
        name: 'become-a-distributor',
        component: () => import(
        /* webpackChunkName: "become-a-distributor" */ '../views/auth/view-become-distributor.vue'),
        meta: {
            layout: CmMainLayout,
            requiresAuth: false,
            title: { key: 'routes.register', choice: null },
            breadcrumb: [{ name: { key: 'register', choice: null } }],
            pageTitle: 'Distributor | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
            type: 'navbar1',
            registrationType: UserType.Distributor,
            paddingTop: false,
        },
    },
    {
        path: '/become-a-sales-agent',
        name: 'become-a-sales-agent',
        component: () => import(
        /* webpackChunkName: "become-a-sales-agent" */ '../views/auth/view-become-sales-agent.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            requiresAuth: false,
            title: { key: 'routes.register', choice: null },
            breadcrumb: [{ name: { key: 'register', choice: null } }],
            pageTitle: 'Sales Agent | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
            registrationType: UserType.Agent,
            paddingTop: false,
        },
    },
    {
        path: '/become-a-reseller',
        name: 'become-a-reseller',
        component: () => import(
        /* webpackChunkName: "become-a-sales-agent" */ '../views/auth/view-become-reseller.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            requiresAuth: false,
            title: { key: 'routes.register', choice: null },
            breadcrumb: [{ name: { key: 'register', choice: null } }],
            pageTitle: 'Reseller | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
            registrationType: UserType.Reseller,
            paddingTop: false,
        },
    },
    // {
    //   path: "/register/consumer",
    //   name: "register-consumer",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "register" */ "../views/auth/view-register-end-customers.vue"
    //     ),
    //   meta: {
    //     layout: CmAuthLayout,
    //     requiresAuth: false,
    //     title: { key: "routes.register", choice: null },
    //     breadcrumb: [{ name: { key: "register", choice: null } }],
    //     pageTitle: "Agent | Seamless1",
    //   },
    // },
    // {
    //   path: "/register/professional",
    //   name: "professional",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "register" */ "../views/auth/view-register-professionals.vue"
    //     ),
    //   meta: {
    //     layout: CmAuthLayout,
    //     requiresAuth: false,
    //     title: { key: "routes.register", choice: null },
    //     breadcrumb: [{ name: { key: "register", choice: null } }],
    //   },
    // },
    // {
    //   path: "/register/salon-owner",
    //   name: "salon-owner",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "register" */ "../views/auth/view-register-salon-owners.vue"
    //     ),
    //   meta: {
    //     layout: CmAuthLayout,
    //     requiresAuth: false,
    //     title: { key: "routes.register", choice: null },
    //     breadcrumb: [{ name: { key: "register", choice: null } }],
    //     pageTitle: "Distributor | Seamless1",
    //   },
    // },
    // {
    //   path: "/register/reseller",
    //   name: "reseller",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "register" */ "../views/auth/view-register-reseller.vue"
    //     ),
    //   meta: {
    //     layout: CmAuthLayout,
    //     requiresAuth: false,
    //     title: { key: "routes.register", choice: null },
    //     breadcrumb: [{ name: { key: "register", choice: null } }],
    //   },
    // },
    // {
    //   path: "/register/distributor",
    //   name: "register-distributor",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "register" */ "../views/auth/view-register-distributors.vue"
    //     ),
    //   meta: {
    //     layout: CmAuthLayout,
    //     requiresAuth: false,
    //     title: { key: "routes.register", choice: null },
    //     breadcrumb: [{ name: { key: "register", choice: null } }],
    //   },
    // },
    // {
    //   path: "/register/agent",
    //   name: "register-agent",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "register" */ "../views/auth/view-register-agent.vue"
    //     ),
    //   meta: {
    //     layout: CmAuthLayout,
    //     // fullLayout:true,
    //     requiresAuth: false,
    //     title: { key: "routes.register", choice: null },
    //     breadcrumb: [{ name: { key: "register", choice: null } }],
    //     pageTitle: "Reseller | Seamless1",
    //   },
    // },
    {
        path: '/password-reset',
        name: 'password-reset',
        component: () => import(/* webpackChunkName: "password-reset" */ '../views/auth/view-password-reset.vue'),
        meta: {
            layout: CmAuthLayout,
            // fullLayout:true,
            requiresAuth: false,
            title: { key: 'routes.password-reset', choice: null },
            breadcrumb: [{ name: { key: 'password-reset', choice: null } }],
            pageTitle: 'Password Reset | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/password-recovery/:token',
        name: 'password-recovery',
        component: () => import(
        /* webpackChunkName: "password-recovery" */ '../views/auth/view-password-recovery.vue'),
        meta: {
            layout: CmAuthLayout,
            // fullLayout:true,
            requiresAuth: false,
            title: { key: 'routes.password-recovery', choice: null },
            breadcrumb: [{ name: { key: 'password-recovery', choice: null } }],
            pageTitle: 'Password Recovery | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/shop',
        name: 'shop',
        component: () => import(/* webpackChunkName: "shop" */ '../views/view-shop-grid.vue'),
        meta: {
            layout: CmMainLayout,
            paddingTop: false,
            paddingBottom: false,
            requiresAuth: false,
            type: 'navbar1',
            title: { key: 'routes.shop', choice: null },
            breadcrumb: [{ name: { key: 'shop', choice: null } }],
            pageTitle: 'Shop | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/shop/items/:uuid',
        name: 'product-single',
        component: () => import(/* webpackChunkName: "product-single" */ '../views/product/view-product-single.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            paddingTop: false,
            paddingBottom: false,
            requiresAuth: false,
            title: { key: 'routes.product-single', choice: null },
            breadcrumb: [{ name: { key: 'product-single', choice: null } }],
            // pageTitle: "Single Product | Seamless1",
        },
        beforeEnter: async (to) => {
            const productStore = useProductStore();
            if (!productStore.products.list.length) {
                await productStore.SET_PRODUCTS('/json/filtered_v1.0.json', 'FILTER');
            }
            const product = await productStore.setProduct(to.params.uuid);
            if (product && product.productDescShort) {
                document.title = product.productDescShort;
            }
        },
    },
    {
        path: '/shop/product-bundle/:uuid',
        name: 'single-product-bundle',
        component: () => import(
        /* webpackChunkName: "single-product-bundle" */ '../views/product/view-single-product-bundle.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            paddingTop: false,
            paddingBottom: false,
            requiresAuth: false,
            title: { key: 'routes.single-product-bundle', choice: null },
            breadcrumb: [{ name: { key: 'single-product-bundle', choice: null } }],
            // pageTitle: "Single Product Bundle | Seamless1",
        },
        beforeEnter: async (to) => {
            const productStore = useProductStore();
            const product = await productStore.setProduct(to.params.uuid);
            if (product && product.productDescShort) {
                document.title = product.productDescShort;
            }
        },
    },
    {
        path: '/shop/:category/:uuid',
        name: 'single-product-category',
        component: () => import(
        /* webpackChunkName: "single-product-category" */ '../views/product/view-single-product-category.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            paddingTop: false,
            paddingBottom: false,
            requiresAuth: false,
            title: { key: 'routes.single-product-category', choice: null },
            breadcrumb: [{ name: { key: 'single-product-category', choice: null } }],
            // pageTitle: "Single Product Category | Seamless1",
        },
        beforeEnter: async (to) => {
            const productStore = useProductStore();
            if (!productStore.allProducts.list.length) {
                await productStore.SET_PRODUCTS('/json/final_v1.0.json');
            }
            const product = await productStore.setProduct(to.params.uuid);
            if (product && product.productDescShort) {
                document.title = product.productDescShort;
            }
        },
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: () => import(/* webpackChunkName: "about-us" */ '../views/view-about-us.vue'),
        meta: {
            layout: CmMainLayout,
            type: 'navbar1',
            paddingTop: false,
            paddingBottom: false,
            requiresAuth: false,
            title: { key: 'routes.about-us', choice: null },
            breadcrumb: [{ name: { key: 'about-us', choice: null } }],
            pageTitle: 'Seamless1 | Your Platform To Grow | About Us',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/terms-and-conditions',
        name: 'terms-conditions',
        component: () => import(/* webpackChunkName: "terms-conditions" */ '../views/view-terms-conditions.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            paddingTop: false,
            paddingBottom: false,
            requiresAuth: false,
            title: { key: 'routes.terms-conditions', choice: null },
            breadcrumb: [{ name: { key: 'terms-conditions', choice: null } }],
            pageTitle: 'Terms & Conditions | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/disclaimer',
        name: 'disclaimer',
        component: () => import(/* webpackChunkName: "disclaimer" */ '../views/view-disclaimer.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            paddingTop: false,
            paddingBottom: false,
            requiresAuth: false,
            title: { key: 'routes.disclaimer', choice: null },
            breadcrumb: [{ name: { key: 'disclaimer', choice: null } }],
            pageTitle: 'Disclaimer | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        beforeEnter() {
            document.body.classList.remove('showCookie');
        },
        component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/view-privacy-policy.vue'),
        meta: {
            neverShowCookieBanner: true,
            type: 'navbar1',
            layout: CmMainLayout,
            paddingTop: false,
            paddingBottom: false,
            requiresAuth: false,
            title: { key: 'routes.privacy-policy', choice: null },
            breadcrumb: [{ name: { key: 'privacy-policy', choice: null } }],
            pageTitle: 'Privacy Policy | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/online-education',
        name: 'online-education',
        component: () => import(/* webpackChunkName: "online-education" */ '../views/view-online-education.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            paddingTop: false,
            paddingBottom: false,
            requiresAuth: false,
            title: { key: 'routes.online-education', choice: null },
            breadcrumb: [{ name: { key: 'online-education', choice: null } }],
            pageTitle: 'Education - Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/online-education/seamless1/hair-extension-course',
        name: 'online-education-course',
        component: () => import(
        /* webpackChunkName: "online-education-course" */ '../views/view-online-education-course.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            paddingTop: false,
            paddingBottom: false,
            requiresAuth: false,
            title: { key: 'routes.online-education', choice: null },
            breadcrumb: [{ name: { key: 'online-education', choice: null } }],
            pageTitle: 'Education - Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/brands',
        name: 'brands',
        component: () => import(/* webpackChunkName: "brands" */ '../views/view-brands.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            paddingTop: false,
            paddingBottom: false,
            requiresAuth: false,
            title: { key: 'routes.brands', choice: null },
            breadcrumb: [{ name: { key: 'brands', choice: null } }],
            pageTitle: 'Brands | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/brands/seamless1',
        name: 'brand-seamless1',
        component: () => import(/* webpackChunkName: "brand-seamless1" */ '../views/view-brand-seamless1.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            paddingTop: false,
            paddingBottom: false,
            requiresAuth: false,
            title: { key: 'routes.brand-seamless1', choice: null },
            breadcrumb: [{ name: { key: 'brand-seamless1', choice: null } }],
            pageTitle: 'Seamless1 | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/brands/peptame',
        name: 'brand-peptame',
        component: () => import(/* webpackChunkName: "brand-peptame" */ '../views/view-brand-peptame.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            paddingTop: false,
            paddingBottom: false,
            requiresAuth: false,
            title: { key: 'routes.brand-peptame', choice: null },
            breadcrumb: [{ name: { key: 'brand-peptame', choice: null } }],
            pageTitle: 'Peptame | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/shop/items',
        redirect: '/shop',
        sensitive: true,
    },
    // {
    //   path: "/cart",
    //   name: "cart",
    //   component: () =>
    //     import(/* webpackChunkName: "cart" */ "../views/view-cart.vue"),
    //   meta: {
    //     type: "navbar1",
    //     layout: CmMainLayout,
    //     requiresAuth: false,
    //     title: "Cart",
    //     breadcrumb: [
    //       { path: "/", text: "Home" },
    //       { path: "/cart", text: "Cart" },
    //     ],
    //     pageTitle: "Cart | Seamless1",
    //     paddingTop: false,
    //   },
    // },
    {
        path: '/checkout',
        redirect: '/checkout/details',
        name: 'checkout',
        component: () => import(/* webpackChunkName: "checkout" */ '../views/checkout/view-checkout.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            requiresAuth: true,
            title: 'Checkout',
            breadcrumb: [
                { path: '/', text: 'Home' },
                { path: '/checkout', text: 'Checkout' },
            ],
            pageTitle: 'Checkout | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
            paddingTop: false,
        },
        children: [
            {
                path: '/checkout/cart',
                name: 'checkout-cart',
                component: () => import(/* webpackChunkName: "checkout" */ '../views/view-cart.vue'),
                meta: {
                    type: 'navbar1',
                    layout: CmMainLayout,
                    requiresAuth: false,
                    title: 'Cart',
                    breadcrumb: [
                        { path: '/', text: 'Home' },
                        { path: '/checkout/cart', text: 'Checkout' },
                    ],
                    pageTitle: 'Checkout / Cart | Seamless1',
                    pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                },
            },
            {
                path: '/checkout/shipping',
                name: 'checkout-shipping',
                component: () => import(/* webpackChunkName: "checkout" */ '../views/checkout/view-checkout-details.vue'),
                meta: {
                    type: 'navbar1',
                    layout: CmMainLayout,
                    requiresAuth: false,
                    title: 'Shipping',
                    breadcrumb: [
                        { path: '/', text: 'Home' },
                        { path: '/checkout/shipping', text: 'Checkout' },
                    ],
                    pageTitle: 'Checkout / Shipping | Seamless1',
                    pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                },
            },
            {
                path: '/checkout/payments',
                name: 'checkout-payments',
                component: () => import(
                /* webpackChunkName: "checkout-payments" */ '../views/checkout/view-checkout-payments.vue'),
                meta: {
                    type: 'navbar1',
                    layout: CmMainLayout,
                    requiresAuth: false,
                    title: 'Payments',
                    breadcrumb: [
                        { path: '/', text: 'Home' },
                        { path: '/checkout/payments', text: 'Payments' },
                    ],
                    pageTitle: 'Checkout / Payments | Seamless1',
                    pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
                },
            },
            // {
            //   path: "/checkout/review",
            //   name: "checkout-review",
            //   component: () =>
            //     import(
            //       /* webpackChunkName: "checkout-review" */ "../views/checkout/view-checkout-review.vue"
            //     ),
            //   meta: {
            //     type: "navbar1",
            //     layout: CmMainLayout,
            //     requiresAuth: false,
            //     title: "Checkout review",
            //     breadcrumb: [
            //       { path: "/", text: "Home" },
            //       { path: "/checkout/review", text: "Payments" },
            //     ],
            //     pageTitle: "Checkout | Seamless1",
            //   },
            // },
        ],
    },
    {
        path: '/checkout/loading',
        name: 'checkout-loading',
        component: () => import(
        /* webpackChunkName: "checkout-loading" */ '../views/checkout/view-checkout-loading.vue'),
        meta: {
            requiresAuth: false,
            title: 'Checkout loading',
            breadcrumb: [
                { path: '/', text: 'Home' },
                { path: '/checkout/loading', text: 'Loading' },
            ],
            pageTitle: 'Checkout / Loading | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/checkout-complete',
        name: 'checkout-complete',
        component: () => import(
        /* webpackChunkName: "checkout-complete" */ '../views/checkout/view-checkout-complete.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            requiresAuth: false,
            title: 'Checkout complete',
            breadcrumb: [
                { path: '/', text: 'Home' },
                { path: '/checkout/details', text: 'Checkout' },
            ],
            pageTitle: 'Checkout / Complete | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/checkout/failure',
        name: 'checkout-failure',
        component: () => import(
        /* webpackChunkName: "checkout-failure" */ '../views/checkout/view-checkout-failure.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            requiresAuth: false,
            title: 'Checkout failure',
            breadcrumb: [
                { path: '/', text: 'Home' },
                { path: '/checkout/failure', text: 'Checkout Failure' },
            ],
            pageTitle: 'Checkout / Failure | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/product-compare',
        name: 'product-compare',
        component: () => import(/* webpackChunkName: "product-compare" */ '../views/view-product-compare.vue'),
        meta: {
            layout: CmMainLayout,
            type: 'navbar1',
            requiresAuth: false,
            title: { key: 'routes.product-compare', choice: null },
            breadcrumb: [
                // { name: { key: "product-compare", choice: null } },
                { path: '/', text: 'Home' },
                { path: '/product-compare', text: 'Compare' },
            ],
            pageTitle: 'Product Compare | Seamless1',
            paddingBottom: false,
            paddingTop: false,
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    // {
    //   path: "/track-orders",
    //   name: "track-orders",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "track-orders" */ "../views/view-track-order.vue"
    //     ),
    //   meta: {
    //     layout: CmMainLayout,
    //     type: "navbar1",
    //     requiresAuth: false,
    //     title: { key: "routes.track-orders", choice: null },
    //     breadcrumb: [{ name: { key: "track-orders", choice: null } }],
    //     paddingBottom: false,
    //     paddingTop: false,
    //     pageTitle: "Track Orders | Seamless1",
    //     pageDesription:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam."
    //   },
    // },
    {
        path: '/legal-notice',
        name: 'Legal notice',
        component: () => import(/* webpackChunkName: "imprint" */ '../views/view-legal-notice.vue'),
        meta: {
            layout: CmMainLayout,
            type: 'navbar1',
            requiresAuth: false,
            title: { key: 'routes.imprint', choice: null },
            breadcrumb: [{ name: { key: 'imprint', choice: null } }],
            // paddingBottom: false,
            paddingTop: false,
            paddingBottom: false,
            pageTitle: 'Legal Notice | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/career',
        name: 'career',
        component: () => import(/* webpackChunkName: "career" */ '../views/career/view-career.vue'),
        meta: {
            layout: CmMainLayout,
            type: 'navbar1',
            requiresAuth: false,
            title: { key: 'routes.career', choice: null },
            breadcrumb: [{ name: { key: 'career', choice: null } }],
            // paddingBottom: false,
            paddingTop: false,
            pageTitle: 'Career | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/career/:title',
        name: 'job',
        component: () => import(/* webpackChunkName: "job" */ '../views/career/view-job.vue'),
        meta: {
            layout: CmMainLayout,
            type: 'navbar1',
            requiresAuth: false,
            title: { key: 'routes.job', choice: null },
            breadcrumb: [{ name: { key: 'job', choice: null } }],
            // paddingBottom: false,
            paddingTop: false,
            pageTitle: 'Career | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/contact',
        name: 'contact-us',
        component: () => import(/* webpackChunkName: "contact-us" */ '../views/view-contact-us.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            requiresAuth: false,
            title: { key: 'routes.contact-us', choice: null },
            breadcrumb: [{ name: { key: 'contact-us', choice: null } }],
            paddingBottom: false,
            pageTitle: 'Contact Us | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
            paddingTop: false,
        },
    },
    {
        path: '/club',
        name: 'club',
        component: () => import(/* webpackChunkName: "club" */ '../views/view-club.vue'),
        meta: {
            layout: CmMainLayout,
            paddingTop: false,
            paddingBottom: false,
            requiresAuth: false,
            type: 'navbar1',
            title: { key: 'routes.club', choice: null },
            breadcrumb: [{ name: { key: 'club', choice: null } }],
            pageTitle: 'Club | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import(/* webpackChunkName: "faq" */ '../views/view-faq.vue'),
        meta: {
            layout: CmMainLayout,
            paddingTop: false,
            paddingBottom: false,
            requiresAuth: false,
            type: 'navbar1',
            title: { key: 'routes.faq', choice: null },
            breadcrumb: [{ name: { key: 'faq', choice: null } }],
            pageTitle: 'FAQs | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/get-started',
        name: 'get-started',
        component: () => import(/* webpackChunkName: "faq" */ '../views/view-get-started.vue'),
        meta: {
            layout: CmMainLayout,
            paddingTop: false,
            paddingBottom: true,
            requiresAuth: false,
            type: 'navbar1',
            title: { key: 'routes.get-started', choice: null },
            breadcrumb: [{ name: { key: 'get-started', choice: null } }],
            pageTitle: 'Get Started | Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/watch/:uuid',
        name: 'videoplayer',
        component: () => import(/* webpackChunkName: "videoplayer" */ '../views/view-videoplayer.vue'),
        // beforeEnter: async (to, from, next) => {
        //   const mediaObjectStore = useMediaObjectStore();
        //   const purchasesList = await mediaObjectStore.PURCHASES_LIST({
        //     filter: {},
        //     search: {},
        //     order: {},
        //     page: 1,
        //     itemsPerPage: 50,
        //     limit: null,
        //   }, serviceTypeNames.COURSE_LIST);
        //   const userStore = useUserStore();
        //   if (!userStore.isLoggedIn) {
        //     next({ name: "home" });
        //   } else if (
        //     userStore.isLoggedIn &&
        //     !purchasesList.list.length
        //   ) {
        //     next({
        //       name: "product-single",
        //       params: { uuid: "6e3b938a-233b-40be-8a20-f38ea8a13437" },
        //     });
        //   } else if (
        //     userStore.isLoggedIn &&
        //     purchasesList.list.length
        //   ) {
        //     next();
        //   } else {
        //     next({ name: "home" });
        //   }
        // },
        meta: {
            // layout: CmMainLayout,
            // paddingTop: false,
            // paddingBottom: false,
            requiresAuth: true,
            //type: "navbar1",
            title: { key: 'routes.videoplayer', choice: null },
            breadcrumb: [{ name: { key: 'videoplayer', choice: null } }],
            pageTitle: 'Education - Seamless1',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/empty-cart',
        name: 'empty-cart',
        component: () => import(/* webpackChunkName: "empty-cart" */ '../views/view-empty-cart.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            requiresAuth: false,
            title: { key: 'routes.empty-cart', choice: null },
            breadcrumb: [{ name: { key: 'empty-cart', choice: null } }],
            pageTitle: 'Seamless1 | Your Platform To Grow | Password Recovery',
            pageDesription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem tempora voluptate blanditiis corrupti sed natus sequi dolor aliquid. Porro, repellendus deleniti? At culpa ipsam, consectetur obcaecati repellendus officia odio quisquam.',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404-not-found',
        component: () => import(/* webpackChunkName: "404-not-found" */ '../views/view-not-found.vue'),
        meta: {
            type: 'navbar1',
            layout: CmMainLayout,
            requiresAuth: false,
            title: { key: 'routes.404-not-found', choice: null },
            breadcrumb: [{ name: { key: '404-not-found', choice: null } }],
            pageTitle: 'Seamless1 | Your Platform To Grow | Empty Cart',
        },
    },
];
const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_APPLICATION_BASE_URL),
    routes,
    //dont jump to top if url param changes on same page
    scrollBehavior: (to, from, savedPosition) => {
        //if url param changes on same page, dont jump to top
        if (to.path === from.path) {
            return false;
        }
        else if (savedPosition) {
            return savedPosition;
        }
        else if (to.path && to.hash) {
            return {
                el: `#${to.hash}`,
                top: 0,
            };
        }
        else {
            return { top: 0, behavior: 'smooth' };
        }
    },
});
router.beforeEach((to, from, next) => {
    if (typeof to.meta.pageTitle === 'string') {
        document.title = to.meta.pageTitle;
        const metaEl = document.createElement('meta');
        metaEl.setAttribute('name', 'description');
        metaEl.setAttribute('content', to.meta.pageDesription);
        document.getElementsByTagName('head')[0].appendChild(metaEl);
    }
    // if (to.fullPath.includes('my-account') || to.fullPath.includes('/watch')) {
    //   localStorage.setItem('savedPage', to.fullPath);
    // }
    const userStore = useUserStore();
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!userStore.isLoggedIn) {
            next({
                path: '/',
                query: { redirect: to.fullPath },
            });
        }
        else {
            next();
        }
    }
    else {
        next(); // make sure to always call next()!
    }
});
export default router;
