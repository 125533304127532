import { defineStore } from "pinia";
export var loadingNames;
(function (loadingNames) {
    loadingNames["SALES_ADD"] = "SALES_ADD";
    loadingNames["VERIFY_VAT"] = "VERIFY_VAT";
    loadingNames["CART_SHIPPING_CREATE"] = "CART_SHIPPING_CREATE";
    loadingNames["VOUCHER_LIST"] = "VOUCHER_LIST";
    loadingNames["OBJECT_GET"] = "OBJECT_GET";
    loadingNames["USER_REGISTER"] = "USER_REGISTER";
    loadingNames["USER_LOGIN"] = "USER_LOGIN";
    loadingNames["USER_LOGOUT"] = "USER_LOGOUT";
    loadingNames["USER_LOST_PASSWORD"] = "USER_LOST_PASSWORD";
    loadingNames["USER_RECOVER_PASSWORD"] = "USER_RECOVER_PASSWORD";
    loadingNames["USER_GET_PROFILE"] = "USER_GET_PROFILE";
    loadingNames["USER_UPDATE_PROFILE"] = "USER_UPDATE_PROFILE";
    loadingNames["USER_UPDATE_PASSWORD"] = "USER_UPDATE_PASSWORD";
    loadingNames["CAPTCHA_VERIFY"] = "CAPTCHA_VERIFY";
    loadingNames["VOUCHER_REDEEM"] = "VOUCHER_REDEEM";
    loadingNames["NOTIFICATION_LIST"] = "NOTIFICATION_LIST";
    loadingNames["PURCHASES_LIST"] = "PURCHASES_LIST";
    loadingNames["BILLING_LIST"] = "BILLING_LIST";
    loadingNames["USERS_LIST"] = "USERS_LIST";
    loadingNames["USERS_ADD"] = "USERS_ADD";
    loadingNames["USERS_REMOVE"] = "USERS_REMOVE";
    loadingNames["NOTIFICATION_UPDATE"] = "NOTIFICATION_UPDATE";
    loadingNames["CART_OBTAIN"] = "CART_OBTAIN";
    loadingNames["CART_CLEAR"] = "CART_CLEAR";
    loadingNames["CART_DELETE"] = "CART_DELETE";
    loadingNames["CART_ITEM_ADD"] = "CART_ITEM_ADD";
    loadingNames["CART_ITEM_UPDATE"] = "CART_ITEM_UPDATE";
    loadingNames["CART_ITEM_DELETE"] = "CART_ITEM_DELETE";
    loadingNames["CART_COUPON_ADD"] = "CART_COUPON_ADD";
    loadingNames["CART_COUPON_DELETE"] = "CART_COUPON_DELETE";
    loadingNames["CART_COUPON_LIST"] = "CART_COUPON_LIST";
    loadingNames["CART_SALES_ORDER_CREATE"] = "CART_SALES_ORDER_CREATE";
    loadingNames["USER_ACTIVATE"] = "USER_ACTIVATE";
    loadingNames["PAYMENT_GATEWAY_LIST"] = "PAYMENT_GATEWAY_LIST";
    loadingNames["PAYMENT_GATEWAY_GET"] = "PAYMENT_GATEWAY_GET";
    loadingNames["PAYMENT_METHOD_LIST"] = "PAYMENT_METHOD_LIST";
    loadingNames["PAYMENT_METHOD_GET"] = "PAYMENT_METHOD_GET";
    loadingNames["PAYMENT_METHOD_CREATE"] = "PAYMENT_METHOD_CREATE";
    loadingNames["PAYMENT_INIT"] = "PAYMENT_INIT";
    loadingNames["PAYMENT_CONFIRM"] = "PAYMENT_CONFIRM";
    loadingNames["SCENARIO_SG10_X00030"] = "SCENARIO_SG10_X00030";
    // PAYMENT_CREATE = "PAYMENT_CREATE",
    loadingNames["ACTIVITY_LOG_LIST"] = "ACTIVITY_LOG_LIST";
    loadingNames["COMMENTS_LIST"] = "COMMENTS_LIST";
    loadingNames["COMMENT_CREATE"] = "COMMENT_CREATE";
    loadingNames["COMMENT_DELETE"] = "COMMENT_DELETE";
    loadingNames["SHORTLIST_LIST"] = "SHORTLIST_LIST";
    loadingNames["SHORTLIST_ITEM_LIST"] = "SHORTLIST_ITEM_LIST";
    loadingNames["SHORTLIST_ADD"] = "SHORTLIST_ADD";
    loadingNames["SHORTLIST_GET"] = "SHORTLIST_GET";
    loadingNames["SHORTLIST_ITEM_GET"] = "SHORTLIST_ITEM_GET";
    loadingNames["SHORTLIST_ITEM_CREATE"] = "SHORTLIST_ITEM_CREATE";
    loadingNames["SHORTLIST_ITEM_ADD"] = "SHORTLIST_ITEM_ADD";
    loadingNames["SHORTLIST_DELETE"] = "SHORTLIST_DELETE";
    loadingNames["SHORTLIST_ITEM_DELETE"] = "SHORTLIST_ITEM_DELETE";
    loadingNames["SHORTLIST_ITEM_UPDATE"] = "SHORTLIST_ITEM_UPDATE";
    loadingNames["FILTERED_PRODUCTS_LOADING"] = "FILTERED_PRODUCTS_LOADING";
    loadingNames["PRODUCT_LIST"] = "SCENARIO_SG01_X00001";
    loadingNames["USER_GROUP"] = "SCENARIO_SG01_X00002";
    loadingNames["EDUCATION_GET"] = "EDUCATION_GET";
    loadingNames["EDUCATION_LIST"] = "EDUCATION_LIST";
    loadingNames["PAYMENT_METHOD_DELETE"] = "PAYMENT_METHOD_DELETE";
    loadingNames["PAYMENT_METHOD_UPDATE"] = "PAYMENT_METHOD_UPDATE";
    loadingNames["ORDER_RETURN_LIST"] = "ORDER_RETURN_LIST";
    loadingNames["SHORTLIST_UPDATE"] = "SHORTLIST_UPDATE";
    loadingNames["EDUCATION_ADD"] = "EDUCATION_ADD";
    loadingNames["EDUCATION_UPDATE"] = "EDUCATION_UPDATE";
    loadingNames["PAYMENT_METHOD_SET_DEFAULT"] = "PAYMENT_METHOD_SET_DEFAULT";
})(loadingNames || (loadingNames = {}));
export const useLoadingStore = defineStore("loadingModule", {
    state: () => {
        return {
            VERIFY_VAT: false,
            SHORTLIST_UPDATE: false,
            ORDER_RETURN_LIST: false,
            PAYMENT_METHOD_UPDATE: false,
            PAYMENT_METHOD_DELETE: false,
            ACTIVITY_LOG_LIST: false,
            BILLING_LIST: false,
            CAPTCHA_VERIFY: false,
            CART_CLEAR: false,
            CART_COUPON_ADD: false,
            CART_COUPON_DELETE: false,
            CART_COUPON_LIST: false,
            CART_DELETE: false,
            CART_ITEM_ADD: false,
            CART_ITEM_DELETE: false,
            CART_ITEM_UPDATE: false,
            CART_OBTAIN: false,
            CART_SALES_ORDER_CREATE: false,
            COMMENT_CREATE: false,
            COMMENT_UPDATE: false,
            COMMENT_DELETE: false,
            COMMENT_LIST: false,
            COURSE_GET: false,
            COURSE_LIST: false,
            NOTIFICATION_LIST: false,
            NOTIFICATION_UPDATE: false,
            OBJECT_GET: false,
            PAYMENT_CONFIRM: false,
            SCENARIO_SG10_X00031: false,
            // PAYMENT_CREATE: false,
            PAYMENT_GATEWAY_GET: false,
            PAYMENT_GATEWAY_LIST: false,
            SCENARIO_SG10_X00029: false,
            PAYMENT_METHOD_CREATE: false,
            PAYMENT_METHOD_GET: false,
            PAYMENT_METHOD_LIST: false,
            SCENARIO_SG10_X00030: false,
            PURCHASES_LIST: false,
            SALES_INVOICE_GET: false,
            SALES_ORDER_GET: false,
            SALES_ORDER_LIST: false,
            SHORTLIST_CREATE: false,
            SHORTLIST_DELETE: false,
            SHORTLIST_GET: false,
            SHORTLIST_ITEM_CREATE: false,
            SHORTLIST_ITEM_DELETE: false,
            SHORTLIST_ITEM_GET: false,
            SHORTLIST_ITEM_LIST: false,
            SHORTLIST_ITEM_UPDATE: false,
            SHORTLIST_LIST: false,
            SUBSCRIPTION_LIST: false,
            USER_ACTIVATE: false,
            USER_CREATE: false,
            USER_GET_PROFILE: false,
            USER_LIST: false,
            USER_LOGIN: false,
            USER_LOGOUT: false,
            USER_LOST_PASSWORD: false,
            USER_RECOVER_PASSWORD: false,
            USER_REGISTER: false,
            USER_REMOVE: false,
            USER_UPDATE_PASSWORD: false,
            USER_UPDATE_PROFILE: false,
            VOUCHER_LIST: false,
            VOUCHER_REDEEM: false,
            FILTERED_PRODUCTS_LOADING: false,
            SCENARIO_SG01_X00001: false,
            SCENARIO_SG01_X00002: false,
            SCENARIO_SG10_X00032: false,
            EDUCATION_GET: false,
            EDUCATION_LIST: false,
            CART_SHIPPING_CREATE: false,
            EDUCATION_ADD: false,
            EDUCATION_UPDATE: false,
            SALES_ADD: false,
            SHORTLIST_ITEM_ADD: false,
            PAYMENT_METHOD_SET_DEFAULT: false
        };
    },
    actions: {
        setLoadingState(
        // stateName: keyof typeof serviceTypeNames,
        stateName, loading) {
            this[stateName] = loading;
        },
    },
});
