import { i18n } from '@/i18n';
import cliffMarketService from '@/services/cliff-market.service';
import { useLoadingStore } from '@/store/modules/app/loading.module';
import { useToastStore } from '@/store/modules/app/toast.module';
import { Context } from '@/interfaces/application';
import { serviceTypeNames } from './service';
const channelUniqueIdRequests = [
    serviceTypeNames.CART_OBTAIN,
    serviceTypeNames.CART_ITEM_ADD,
    serviceTypeNames.CART_ITEM_DELETE,
    serviceTypeNames.CART_COUPON_ADD,
    serviceTypeNames.CART_COUPON_DELETE,
    serviceTypeNames.CART_SALES_ORDER_CREATE,
    serviceTypeNames.SCENARIO_SG01_X00001,
    serviceTypeNames.CART_SHIPPING_CREATE,
    serviceTypeNames.VERIFY_VAT
];
export const sendRequest = async (type, options, loadingName, messageOnSuccess, context = Context.Account, load = true) => {
    const toastStore = useToastStore();
    const loadingStore = useLoadingStore();
    // Fix this any work around
    const loadingType = loadingName ? loadingName : type;
    loadingStore.setLoadingState(loadingType, load);
    try {
        if (channelUniqueIdRequests.includes(type)) {
            options = {
                ...options,
                channelUniqueId: process.env.VUE_APP_EUSDP_CHANNEL_UNIQUE_ID,
            };
        }
        const res = await cliffMarketService.request(type, options, context);
        if (messageOnSuccess && messageOnSuccess.length) {
            toastStore.success({
                title: i18n.t('toasts.success.title'),
                message: messageOnSuccess,
                duration: 1000
            });
        }
        return res;
    }
    catch (error) {
        const { response, message } = error;
        console.log(response.data, 'RETURN CODE');
        const msg = response
            ? response.data.data && response.data.data.message
                ? response.data.data.message
                : response.data.message
            : message;
        const translationKey = response && response.data ? response.data.returnCode : null;
        const translatedMessage = translationKey && i18n.t(`toasts.danger.messages.${translationKey}`)
            ? i18n.t(`toasts.danger.messages.${translationKey}`)
            : msg;
        toastStore.danger({
            title: i18n.t('toasts.danger.title'),
            message: msg,
        });
        return Promise.reject(error);
    }
    finally {
        loadingStore.setLoadingState(loadingType, false);
    }
};
