import { i18n } from '@/i18n';
import { getRegionTax } from '@/lib/get-user-region';
import { checkImage } from '@/lib/image-layout-parser';
import { sendRequest } from '@/lib/request';
import { serviceTypeNames } from '@/lib/service';
import cliffMarketService from '@/services/cliff-market.service';
import { defineStore } from 'pinia';
import { loadingNames, useLoadingStore } from '../app/loading.module';
import { useToastStore } from '../app/toast.module';
import { useProductStore } from './product.module';
import { Context } from '@/interfaces/application';
import { set } from '@/lib/idb';
import { useUserStore } from './user.module';
export const useCartStore = defineStore('cartModule', {
    state: () => ({
        route: 'cart',
        currency: {
            name: 'EUR',
            symbol: '€',
        },
        cartUniqueId: null,
        productUniqueIds: [],
        items: [],
        cartType: 'UNDEFINED',
        cartStatus: 'CART_ACTIVE',
        totalQuantity: 0,
        totalAmount: 0,
        baseTotal: 0,
        taxes: [],
        discount: 0,
        selectedShippingMethod: 'Pick-up (by customer)',
        shippingPolicyUniqueId: null,
        shippingCharges: 0,
        salesOrderUniqueId: '',
        product: {},
        coupons: [],
        couponCode: null,
        shippingPolicy: null,
        unavailableProducts: [],
    }),
    getters: {
        cartRoute(state) {
            return state.route;
        },
        getSelectedShippingMethod(state) {
            return state.selectedShippingMethod;
        },
        isFreeShipping(state) {
            return (state.items.length &&
                state.items.every((item) => item.productType && item.productType.toLowerCase() === 'product_course'));
        },
    },
    actions: {
        async CLEAR_CART_STORE(obtainNewCart = false) {
            this.cartUniqueId = null;
            this.productUniqueIds = [];
            this.items = [];
            this.cartType = 'UNDEFINED';
            this.cartStatus = 'CART_ACTIVE';
            this.salesOrderUniqueId = '';
            this.totalQuantity = 0;
            this.totalAmount = 0;
            this.taxes = [];
            this.discount = 0;
            this.shippingCharges = 0;
            this.baseTotal = 0;
            this.couponCode = null;
            (this.route = 'cart'),
                (this.currency = {
                    name: 'EUR',
                    symbol: '€',
                });
            this.selectedShippingMethod = 'Pick-up (by customer)';
            this.shippingPolicyUniqueId = null;
            this.product = {};
            this.coupons = [];
            this.unavailableProducts = [];
            this.shippingPolicy = null;
            if (obtainNewCart)
                await this.CART_OBTAIN({
                    // cartUniqueId: this.cartUniqueId ? this.cartUniqueId : null,
                    cartUniqueId: null,
                    currency: 'EUR',
                });
        },
        async CART_OBTAIN(payload) {
            // loading store
            const productStore = useProductStore();
            const cartUniqueId = payload.cartUniqueId;
            try {
                const response = await sendRequest(serviceTypeNames.CART_OBTAIN, {
                    cartUniqueId,
                    currency: payload.currency,
                }, loadingNames.CART_OBTAIN, '', Context.Account, false);
                if (response.cartUniqueId && !this.cartUniqueId) {
                    this.cartUniqueId = response.cartUniqueId;
                }
                if (response.cartItems.length) {
                    if (response.externalData &&
                        response.externalData.items.length &&
                        response.cartUniqueId) {
                        this.items = [...response.externalData.items];
                        this.totalQuantity = response.externalData.total_qty;
                        this.totalAmount = response.externalData.total;
                        this.baseTotal = response.externalData.base_net_total;
                    }
                    if (response.cartItems.length) {
                        response.cartItems.forEach((item, i) => {
                            if (item.cartItemUniqueId) {
                                this.items[i].cartItemUniqueId = item.cartItemUniqueId;
                                this.items[i].productType = item.product.productType;
                            }
                        });
                    }
                    if (response.externalData.taxes.length) {
                        this.taxes = response.externalData.taxes;
                        // console.log('=======response.externalData===',response.externalData.taxes)
                    }
                    if (response.externalData.discount_amount) {
                        this.discount = response.externalData.discount_amount;
                    }
                    if (response.externalData.coupon_code) {
                        this.couponCode = response.externalData.coupon_code;
                    }
                    if (this.items.length && !productStore.allProducts.list.length) {
                        await productStore.SET_PRODUCTS('/json/final_v1.0.json', 'ALL', false);
                    }
                    this.items = await Promise.all(this.items.map(async (cartItem) => {
                        const prodItem = {
                            ...cartItem,
                            url: { name: '', path: '' },
                            productProperties: [],
                        };
                        // if (!productStore.allProducts.list.length) {
                        //   await productStore.SET_PRODUCTS('/json/final_v1.0.json', 'ALL', false);
                        // }
                        const productItem = productStore.allProducts.list.find((prod) => {
                            if (prod.productSKU === cartItem.item_code) {
                                return prod;
                            }
                        });
                        if (productItem) {
                            prodItem.productProperties = productItem.productProperties;
                            prodItem.url.name = productItem.productGroups[0].productGroupDescShort;
                            prodItem.url.path = productItem.productUniqueId;
                            prodItem.item_tax_rate = getRegionTax().percentage;
                            const image = productItem.mediaObjects.find((mediaObject) => {
                                return checkImage(mediaObject, 'IMAGE_PRODUCT_LANDING_PAGE_260') === true;
                            });
                            prodItem.image = image?.mediaObjectPublicUri ?? '';
                        }
                        return prodItem;
                    }));
                }
                else {
                    // this.cartUniqueId = null;
                    this.productUniqueIds = [];
                    this.items = [];
                    this.cartType = 'UNDEFINED';
                    this.cartStatus = 'CART_ACTIVE';
                    this.totalQuantity = 0;
                    this.totalAmount = 0;
                    this.taxes = [];
                    this.discount = 0;
                    this.shippingCharges = 0;
                    this.baseTotal = 0;
                    this.couponCode = null;
                }
                return response;
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
        async CART_ITEM_ADD(payload, obtainCart = true) {
            const toastStore = useToastStore();
            try {
                const response = await sendRequest(serviceTypeNames.CART_ITEM_ADD, payload, loadingNames.CART_ITEM_ADD, '', Context.Account);
                if (obtainCart) {
                    this.CART_OBTAIN({ cartUniqueId: null, currency: 'EUR' });
                }
                toastStore.success({
                    title: i18n.t('toasts.success.title'),
                    message: i18n.t('toasts.success.messages.added-to-cart'),
                });
                return response;
            }
            catch (error) {
                toastStore.danger({
                    title: i18n.t('toasts.danger.title'),
                    message: error.response ? error.response.data.message : error.message,
                });
                return Promise.reject(error);
            }
        },
        async CART_ITEM_REORDER(payload, obtainCart = true, showToast = false, product) {
            const toastStore = useToastStore();
            try {
                const response = await sendRequest(serviceTypeNames.CART_ITEM_ADD, payload, loadingNames.CART_ITEM_ADD, '', Context.Account);
                if (obtainCart) {
                    this.CART_OBTAIN({ cartUniqueId: null, currency: 'EUR' });
                }
                if (showToast) {
                    toastStore.success({
                        title: i18n.t('toasts.success.title'),
                        message: i18n.t('toasts.success.messages.added-to-cart'),
                    });
                }
                return response;
            }
            catch (error) {
                // this.unavailableProducts=[]
                // this.unavailableProducts.push(product);
                toastStore.danger({
                    title: i18n.t('toasts.danger.title'),
                    message: `${product.productDescShort} product could not be Added `,
                });
                return Promise.reject(error);
            }
        },
        async CART_CLEAR(payload) {
            const response = await sendRequest(serviceTypeNames.CART_CLEAR, payload, loadingNames.CART_CLEAR);
            return response;
        },
        async CART_DELETE(payload) {
            const response = await sendRequest(serviceTypeNames.CART_DELETE, payload);
            return response;
        },
        async CART_ITEM_UPDATE(payload) {
            const toastStore = useToastStore();
            const loadingStore = useLoadingStore();
            loadingStore.setLoadingState(serviceTypeNames.CART_ITEM_UPDATE, true);
            try {
                const response = await cliffMarketService.CART_ITEM_UPDATE(payload);
                if (this.cartUniqueId && response && response.cartItems && response.cartItems.length) {
                    this.CART_OBTAIN({ cartUniqueId: null, currency: 'EUR' });
                }
                return response;
            }
            catch (error) {
                toastStore.danger({
                    title: i18n.t('toasts.danger.title'),
                    message: error.response ? error.response.data.message : error.message,
                });
                return Promise.reject(error);
            }
            finally {
                loadingStore.setLoadingState(serviceTypeNames.CART_ITEM_UPDATE, false);
            }
        },
        async CART_ITEM_DELETE(payload) {
            const toastStore = useToastStore();
            try {
                // const cartUniqueId = this.cartUniqueId ? this.cartUniqueId : payload.cartUniqueId;
                const cartUniqueId = this.cartUniqueId ? this.cartUniqueId : payload.cartUniqueId;
                const response = await sendRequest(serviceTypeNames.CART_ITEM_DELETE, {
                    cartItemUniqueId: payload.cartItemUniqueId,
                    cartUniqueId,
                });
                if (this.items.length > 1) {
                    await this.CART_OBTAIN({
                        // cartUniqueId: this.cartUniqueId,
                        cartUniqueId: null,
                        currency: 'EUR',
                    });
                }
                else {
                    this.cartUniqueId = null;
                    await this.CART_OBTAIN({ cartUniqueId: null, currency: 'EUR' });
                    localStorage.removeItem('cartModule');
                }
                return response;
            }
            catch (error) {
                toastStore.danger({
                    title: i18n.t('toasts.danger.title'),
                    message: error.response ? error.response.data.message : error.message,
                });
                return Promise.reject(error);
            }
        },
        async CART_COUPON_ADD(payload) {
            const response = await sendRequest(serviceTypeNames.CART_COUPON_ADD, {
                couponCode: payload.couponCode,
                cartUniqueId: payload.cartUniqueId,
            }, loadingNames.CART_COUPON_ADD);
            if (response.cartItems.length) {
                if (response.externalData && response.externalData.items.length && response.cartUniqueId) {
                    this.items = response.externalData.items;
                    this.totalQuantity = response.externalData.total_qty;
                    this.totalAmount = response.externalData.total;
                    this.baseTotal = response.externalData.base_net_total;
                }
                if (response.externalData.discount_amount) {
                    this.discount = response.externalData.discount_amount;
                }
                if (response.externalData.coupon_code) {
                    this.couponCode = response.externalData.coupon_code;
                }
            }
            return response;
        },
        async SET_SHIPPING_POLICY(state) {
            // const cache = await get('shippingPolicy');
            // if (cache) {
            //   state.shippingPolicy = cache.shippingPolicy[0];
            // }
            const userStore = useUserStore();
            if (userStore.isLoggedIn) {
                const res = await sendRequest(serviceTypeNames.SCENARIO_SG01_X00002, {});
                if (res) {
                    state.shippingPolicy = res.shippingPolicy[0];
                    await set('shippingPolicy', res);
                }
            }
        },
        async CART_COUPON_DELETE(payload) {
            const response = await sendRequest(serviceTypeNames.CART_COUPON_DELETE, payload, loadingNames.CART_COUPON_DELETE);
            if (response.cartItems.length) {
                if (response.externalData && response.externalData.items.length && response.cartUniqueId) {
                    this.items = response.externalData.items;
                    this.totalQuantity = response.externalData.total_qty;
                    this.totalAmount = response.externalData.total;
                    this.baseTotal = response.externalData.base_net_total;
                }
                this.discount = 0;
                this.couponCode = null;
            }
            return response;
        },
        async CART_COUPON_LIST() {
            const response = await sendRequest(serviceTypeNames.CART_COUPON_LIST, {}, loadingNames.CART_COUPON_LIST);
            this.coupons = response.list;
            return response;
        },
        async CART_SALES_ORDER_CREATE(payload) {
            const response = await sendRequest(serviceTypeNames.CART_SALES_ORDER_CREATE, {
                cartUniqueId: payload.cartUniqueId,
                shippingMethod: payload.shippingMethod,
            }, loadingNames.CART_SALES_ORDER_CREATE);
            this.salesOrderUniqueId = response.salesOrderUniqueId;
            return response;
        },
        async SET_QUICK_VIEW_PRODUCT(item_code) {
            const productStore = useProductStore();
            if (!productStore.products.list.length) {
                await productStore.SET_PRODUCTS('/json/filtered_v1.0.json', 'FILTER');
            }
            this.product = productStore.products.list.find((item) => item.productSKU === item_code);
        },
        setShippingMethod(shippingMethod, shippingPolicyUniqueId) {
            this.selectedShippingMethod = shippingMethod;
            this.shippingPolicyUniqueId = shippingPolicyUniqueId;
            this.shippingCharges = this.shippingPolicy?.find((item) => item.policyUniqueId === shippingPolicyUniqueId)?.policyPayload?.shippingPolicyPrices?.[0]?.price;
        },
        async GET_SHIPPING_POLICY() {
            try {
                const res = await sendRequest(serviceTypeNames.SCENARIO_SG01_X00002, {
                    cartTotalNetAmount: this.totalAmount,
                });
                // this.shippingPolicy = [DIGITAL_DELIVERY, ...res.shippingPolicies];
                this.shippingPolicy = [...res.shippingPolicies];
                // await set('SCENARIO_SG01_X00002', res);
                return res;
            }
            catch (error) {
                console.log(error);
            }
            finally {
                if (!this.shippingPolicyUniqueId && this.shippingPolicy && this.shippingPolicy.length) {
                    this.shippingPolicyUniqueId = this.shippingPolicy[0].policyUniqueId;
                    this.selectedShippingMethod = this.shippingPolicy[0].policyDescShort;
                }
            }
        },
        async SHIPPING_ADD() {
            const defaultShippingPolicy = this.shippingPolicy && this.shippingPolicy.length >= 1
                ? this.shippingPolicy[0].policyUniqueId
                : '7e9ac80d-9050-407f-b062-abbdae94b9f5';
            const response = await sendRequest(serviceTypeNames.CART_SHIPPING_CREATE, {
                cartUniqueId: this.cartUniqueId,
                // cartUniqueId: null,
                shippingPolicyUniqueId: this.shippingPolicyUniqueId ?? defaultShippingPolicy,
            }, loadingNames.CART_SHIPPING_CREATE);
            return response;
        },
    },
    persist: {
        paths: [
            'shippingPolicyUniqueId',
            'shippingPolicy',
            'cartUniqueId',
            'salesOrderUniqueId',
            'product',
            'selectedShippingMethod',
            'paymentUniqueId',
            'totalAmount',
        ],
    },
});
