<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_301_4)">
      <path
        d="M7.00008 12.8334C10.2217 12.8334 12.8334 10.2217 12.8334 7.00002C12.8334 3.77836 10.2217 1.16669 7.00008 1.16669C3.77842 1.16669 1.16675 3.77836 1.16675 7.00002C1.16675 10.2217 3.77842 12.8334 7.00008 12.8334Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M1.16675 7H12.8334" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M7.00008 1.16669C8.45916 2.76406 9.28835 4.83704 9.33341 7.00002C9.28835 9.163 8.45916 11.236 7.00008 12.8334C5.541 11.236 4.71181 9.163 4.66675 7.00002C4.71181 4.83704 5.541 2.76406 7.00008 1.16669Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_301_4">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
